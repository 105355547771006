import React, { useState } from 'react'
import { Text } from '../../atoms/Text'
import { Button, Icon } from '../../atoms'
import styled from 'styled-components'
import { africredColors } from '../../../theme/colors'
import { DropdownMenu, Table } from '../../molecules'
import profileImage from '../../../assets/pngs/dp.png'
import {
    pageAnimationConfig,
    sortWithDate,
    filterUser,
    formatDate,
} from '../../../utils'
import { motion } from 'framer-motion'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { screen } from '../../../theme'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { TableFilter } from '../../organisms'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import { UserModal } from '../../molecules/Modal/UserModal'
import { toast } from 'react-toastify'
import { useGridApiRef } from '@mui/x-data-grid'
import axiosPublic from '../../../api/axios'
import { Link, useNavigate } from 'react-router-dom';
import useDebounce from '../../utils/useDebounce';

const Wrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
const ContentWrapper = styled.div`
    width: 100%;
    max-height: calc(84vh - 94px);
    border-radius: 32px;
    background: ${africredColors.neutrals.white[10]};
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media only screen and (${screen.md}) {
        padding: 24px;
    }
`
const TableWrapper = styled.div`
    width: 100%;
    overflow: auto;
    max-height: calc(75vh - 94px);
`
const ClickAbleLabel = styled.a`
    background-color: ${africredColors.neutrals.night[80]};
    display: flex;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 48px;
`
const Flex = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`
function renderHeader(params) {
    return (
        <Text type="p" weight="medium">
            {params.colDef.headerName}
        </Text>
    )
}

const loanAvailable = "Loan available";
const visaServices = "Visa services";

export const Universities = () => {
    const axiosPrivate = useAxiosPrivate()
    const [userDetails, setUserDetails] = useState(null)
    const [openDetailsModal, setOpenDetailsModal] = useState(false)
    const [filterData, setFilterData] = useState({
        searchTerm: '',
        sort: '',
        accountStatus: 'Active',
        accountType: '',
        dateRange: [],
    })
    const searchTerm = useDebounce(filterData.searchTerm, 1000);
    const apiRef = useGridApiRef()
    const navigate = useNavigate();
    const [forceReload, setForceReload] = useState("forcereload");

    const { isPending: genericsDataPending, data: genericsData } = useQuery({
        queryKey: [],
        queryFn: async () => {
            const response = await axiosPublic(`/generic-apis/all-enum`)

            if (response.data) {
                return response.data
            }
        },
    })

    const {
        isPending,
        error,
        data: universities,
        refetch,
    } = useQuery({
        queryKey: [
            'get-universities',
            searchTerm,
            forceReload,
        ],
        queryFn: async () => {
            const response = await axiosPrivate(
                `/university/search?${
                    searchTerm ? `searchTerm=${searchTerm}`: ""
                }&limit=999999`,
            )

            return response.data?.foundUniversities
        },
    })

    const { isMobile } = useScreenSize()

    if (isPending)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                <div className="w-10 h-10">
                    <Icon type="spinner" />
                </div>
                Loading
            </div>
        )

    if (error)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                An error has occurred: {error.message}
            </div>
        )

    async function deleteUniversity(id) {
        try {
            const response = await axiosPrivate.delete(`/university/delete/${id}`)
            toast.success('University deletion completed')
            refetch()
            setForceReload(forceReload + "1")
        } catch (err) {
            toast.error(err.response?.data?.message || "Something went wrong")
        }
    }

    const editUniversity = (university) => {
        localStorage.setItem("university", JSON.stringify(university));
        navigate("/create-university");
    }

    const columns = [
        {
            field: 'schoolName',
            headerName: 'Univerisity name',
            minWidth: 250,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Flex>
                        <Text type="p">{value}</Text>
                    </Flex>
                )
            },
        },
        {
            field: 'website',
            headerName: 'Website',
            minWidth: 200,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <ClickAbleLabel
                        href={value}
                        target='_blank'
                        type="p"
                        color={africredColors.primary.gold[700]}
                    >
                        <Text type="p" weight="bold">
                            View Website
                        </Text>
                    </ClickAbleLabel>
                )
            },
        },
        {
            field: 'state',
            headerName: 'State',
            minWidth: 150,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Flex>
                        <Text type="p">{value}</Text>
                    </Flex>
                )
            },
        },
        {
            field: 'country',
            headerName: 'Country',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Flex>
                        <Text type="p">{value?.name || value}</Text>
                    </Flex>
                )
            },
        },
        {
            field: 'loanAvailable',
            headerName: 'Loan Available',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Flex>
                        <Text type="p">{
                          (~row.opportunities?.indexOf(loanAvailable)) ? "Yes": "No"
                        }</Text>
                    </Flex>
                )
            },
        },
        {
            field: 'visaService',
            headerName: 'Visa Service',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Text type="p" textalign="center">{
                      (~row.opportunities?.indexOf(visaServices)) ? "Yes": "No"
                    }</Text>
                )
            },
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            sortable: false,
            minWidth: 130,
            flex: 1.5,
            renderHeader,
            valueFormatter: ({ value }) => value && formatDate(value),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 80,
            renderHeader,

            getActions: params => [
                ...[
                    <GridActionsCellItem
                        label="Edit Details"
                        showInMenu
                        onClick={() => {
                            editUniversity(params.row)
                        }}
                    />,
                    <GridActionsCellItem
                        label="Delete University"
                        showInMenu
                        onClick={() => deleteUniversity(params.row.id)}
                    />,
                ],
            ],
        },
    ]

    return (
        <Wrapper {...pageAnimationConfig}>
            <div className="flex justify-between">
                <div>
                    <Text type="h3" weight="medium">
                        University or Colleges
                    </Text>
                    <Text type="p" textWrap="nowrap">
                        All universities or college
                    </Text>
                </div>
                <DropdownMenu
                    text={
                        <Button
                            size="sm"
                            border={`1px solid ${africredColors.neutrals.night[950]}`}
                        >
                            <Text type="p" weight="medium" textWrap="nowrap">
                                Add University
                            </Text>
                            <div style={{ width: "1px"}}/>
                            {!isMobile && <Icon type="down-caret" />}
                        </Button>
                    }
                    padding={"12px"}
                >
                    <Button
                        size="xs"
                        bgcolor={africredColors.neutrals.night[100]}
                        onClick={() => {
                            localStorage.removeItem("university")
                            navigate("/create-university");
                        }}
                    >
                        {!isMobile && <Icon type="upload" />}
                        <Text type="p" textWrap="nowrap">
                            Create Manually
                        </Text>
                    </Button>
                    <Button
                        style={{ 
                            marginTop: 10, 
                            height: "40px",
                            padding: "8px 32px",
                        }}
                        onClick={() => {
                            navigate("/upload-university");
                        }}
                    >
                        {!isMobile && <Icon type="boxes" />}
                        <Text type="p" textWrap="nowrap">
                            Upload Excel
                        </Text>
                    </Button>
                </DropdownMenu>
            </div>

            <ContentWrapper>
                <div className="flex justify-between items-center gap-y-2 items-center flex-wrap lg:flex-nowrap">
                    <TableFilter
                        showsCsvButton
                        filterData={filterData}
                        setFilterData={setFilterData}
                        apiRef={apiRef}
                        csvFileName="AfriCred Universities"
                    />
                </div>
                <TableWrapper>
                    <Table
                        apiRef={apiRef}
                        columns={isMobile ? columns.slice(0, 3) : columns}
                        rows={universities}
                        pageSize="100"
                    />
                </TableWrapper>
            </ContentWrapper>

            {userDetails && (
                <UserModal
                    title="Details"
                    open={openDetailsModal}
                    setOpen={setOpenDetailsModal}
                    data={userDetails}
                />
            )}
        </Wrapper>
    )
}

import React from 'react'

export const Icon = props => {
    if (props.type === 'warning') {
        return (
            <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="48" height="48" rx="8" fill="#FFFFE7" />
                <path
                    d="M15.75 22.7531V17.25C15.75 17.0511 15.829 16.8603 15.9697 16.7197C16.1103 16.579 16.3011 16.5 16.5 16.5H31.5C31.6989 16.5 31.8897 16.579 32.0303 16.7197C32.171 16.8603 32.25 17.0511 32.25 17.25V22.7531C32.25 30.6281 25.5656 33.2344 24.2344 33.675C24.0831 33.731 23.9169 33.731 23.7656 33.675C22.4344 33.2344 15.75 30.6281 15.75 22.7531Z"
                    stroke="#FFD700"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M24 21V24.75"
                    stroke="#FFD700"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M24 29.25C24.6213 29.25 25.125 28.7463 25.125 28.125C25.125 27.5037 24.6213 27 24 27C23.3787 27 22.875 27.5037 22.875 28.125C22.875 28.7463 23.3787 29.25 24 29.25Z"
                    fill="#FFD700"
                />
            </svg>
        )
    }
    if (props.type === 'drag') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={30}
                {...props}
            >
                <g
                    data-name="drag (2)"
                    transform="translate(-5.702 -1.503)"
                    fill="#707070"
                >
                    <circle
                        data-name="Ellipse 836"
                        cx={3}
                        cy={3}
                        r={3}
                        transform="translate(5.702 1.503)"
                    />
                    <circle
                        data-name="Ellipse 837"
                        cx={3}
                        cy={3}
                        r={3}
                        transform="translate(5.702 13.503)"
                    />
                    <circle
                        data-name="Ellipse 838"
                        cx={3}
                        cy={3}
                        r={3}
                        transform="translate(5.702 25.503)"
                    />
                    <circle
                        data-name="Ellipse 839"
                        cx={3}
                        cy={3}
                        r={3}
                        transform="translate(17.702 1.503)"
                    />
                    <circle
                        data-name="Ellipse 840"
                        cx={3}
                        cy={3}
                        r={3}
                        transform="translate(17.702 13.503)"
                    />
                    <circle
                        data-name="Ellipse 841"
                        cx={3}
                        cy={3}
                        r={3}
                        transform="translate(17.702 25.503)"
                    />
                </g>
            </svg>
        )
    }
    if (props.type === 'apple') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <path
                    fill="#151515"
                    fillRule="evenodd"
                    d="M15.4 2c.2 1.1-.3 2.2-.9 3-.6.8-1.7 1.5-2.8 1.4-.2-1.1.3-2.1.9-2.9.7-.8 1.8-1.4 2.8-1.5Zm3.2 17.4c.5-.8.8-1.3 1.2-2.2-3.1-1.2-3.6-5.6-.5-7.2-.9-1.2-2.2-1.8-3.5-1.8-.9 0-1.5.2-2.1.4-.5.2-.9.3-1.4.3-.6 0-1-.2-1.6-.4-.6-.2-1.2-.4-1.9-.4-1.4 0-2.9.8-3.8 2.3-1.3 2-1.1 5.9 1 9.1.9 1.2 1.9 2.5 3.2 2.5.6 0 .9-.2 1.3-.3.5-.2 1-.4 1.8-.4.9 0 1.3.2 1.8.4.4.2.7.3 1.3.3 1.4 0 2.4-1.5 3.2-2.6Z"
                    clipRule="evenodd"
                />
            </svg>
        )
    }
    if (props.type === 'google') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <path
                    fill="#151515"
                    d="M21.6 10.2h-9.4v3.7h5.5c-.1.9-.7 2.3-2 3.2-.8.6-2 1-3.5 1-2.6 0-4.9-1.7-5.7-4.2-.2-.6-.3-1.3-.3-2s.1-1.4.3-2c.1-.2.1-.4.2-.5.9-2.1 3-3.6 5.5-3.6 1.9 0 3.1.8 3.9 1.5l2.8-2.8C17.2 3 14.9 2 12.2 2 8.3 2 4.9 4.2 3.3 7.5c-.7 1.4-1.1 2.9-1.1 4.5s.4 3.1 1.1 4.5c1.6 3.3 5 5.5 8.9 5.5 2.7 0 5-.9 6.6-2.4 1.9-1.7 3-4.3 3-7.4 0-.8-.1-1.4-.2-2Z"
                />
            </svg>
        )
    }
    if (props.type === 'secret') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="m14.53 9.47-5.06 5.06a3.576 3.576 0 1 1 5.06-5.06Z" />
                    <path d="M17.82 5.77C16.07 4.45 14.07 3.73 12 3.73c-3.53 0-6.82 2.08-9.11 5.68-.9 1.41-.9 3.78 0 5.19.79 1.24 1.71 2.31 2.71 3.17M8.42 19.53c1.14.48 2.35.74 3.58.74 3.53 0 6.82-2.08 9.11-5.68.9-1.41.9-3.78 0-5.19-.33-.52-.69-1.01-1.06-1.47" />
                    <path d="M15.51 12.7a3.565 3.565 0 0 1-2.82 2.82M9.47 14.53 2 22M22 2l-7.47 7.47" />
                </g>
            </svg>
        )
    }
    if (props.type === 'email') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={1.5}
                >
                    <path d="M17 20.5H7c-3 0-5-1.5-5-5v-7c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v7c0 3.5-2 5-5 5Z" />
                    <path d="m17 9-3.13 2.5c-1.03.82-2.72.82-3.75 0L7 9" />
                </g>
            </svg>
        )
    }

    if (props.type === 'logo') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={props.width || 27}
                height={props.width || 30}
                fill={props.fill || 'none'}
                {...props}
            >
                <g fill="#151515">
                    <path d="M14.3 3.263H10.88L7.05 13.748c-.713.115-1.164-.191-2.254.23-.563.23-2.892-1.76-3.193-2.296C.25 9.118 1.49 8.429 1.49 6.937c0-1.454.187-1.493.6-2.143 1.278-1.76 2.48-.766 2.48-2.488 0-.688.902-1.224 1.615-1.415.451-.154.977.076 1.39-.039C8.101.7 8.515.355 8.702.24c.676-.344 1.54-.306 2.142.038.713.383.75.727.901 1.454.15.46.864.919 1.277 1.034.414.076.827.497 1.278.497ZM26.96 10.916c-.189 1.646-.451 1.952-1.466 3.177-2.066 2.602-2.742 1.645-2.892 4.285L17.117 3.263H14.3c.15 0 .262-.038.413-.153.338-.23-.263-.689.037-1.033.226-.268 1.503.153 1.803.268.977.382 3.006-.192 3.156.038.639.88-.15 1.798.338 2.679.564 1.033 1.052 1.033 1.353 1.607.263.574.3 2.066.976 2.487 1.127.727.676.23 1.578 1.301.977 1.186 3.193-1.033 3.005.46ZM20.46 26.568c-.3.574-.75.918-.901.995-1.165.727-2.93 2.831-4.32 2.372-1.465-.46-.677-.88-1.315-1.837-.864-1.339-.075-1.339-1.202-3.214-.075-.115-.15-.23-.188-.383h7.213l.713 2.067ZM12.496 20.254V18.76l-.864-.689.038-.918c-.263-.344-.526-.497-.751-.689l3.042-8.533 4.47 12.628h-6.01l.075-.306Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'transaction') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={48}
                height={48}
                fill="none"
                {...props}
            >
                <g
                    stroke={props.stroke || '#151515'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                >
                    <path
                        strokeMiterlimit={10}
                        d="M44 12v4.84C44 20 42 22 38.84 22H32V8.02C32 5.8 33.82 4 36.04 4c2.18.02 4.18.9 5.62 2.34A8.05 8.05 0 0 1 44 12Z"
                    />
                    <path
                        strokeMiterlimit={10}
                        d="M4 14v28c0 1.66 1.88 2.6 3.2 1.6l3.42-2.56c.8-.6 1.92-.52 2.64.2l3.32 3.34c.78.78 2.06.78 2.84 0l3.36-3.36c.7-.7 1.82-.78 2.6-.18l3.42 2.56c1.32.98 3.2.04 3.2-1.6V8c0-2.2 1.8-4 4-4H12c-6 0-8 3.58-8 8v2Z"
                    />
                    <path d="M12.5 20h11" />
                </g>
            </svg>
        )
    }

    if (props.type === 'card') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={49}
                height={48}
                fill="none"
                {...props}
            >
                <g
                    stroke={props.stroke || '#151515'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                >
                    <path
                        strokeMiterlimit={10}
                        d="M4.5 17.01h40M12.5 33.01h4M21.5 33.01h8"
                    />
                    <path d="M13.38 7.01H35.6c7.12 0 8.9 1.76 8.9 8.78v16.42c0 7.02-1.78 8.78-8.88 8.78H13.38c-7.1.02-8.88-1.74-8.88-8.76V15.79c0-7.02 1.78-8.78 8.88-8.78Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'wallet') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={48}
                height={48}
                fill="none"
                {...props}
            >
                <g
                    stroke={props.stroke || '#151515'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                >
                    <path d="m25.32 5.036-.06.14-5.8 13.46h-5.7c-1.36 0-2.66.28-3.84.78l3.5-8.36.08-.2.14-.32c.04-.12.08-.24.14-.34 2.62-6.06 5.58-7.44 11.54-5.16Z" />
                    <path d="M36.1 19.036c-.9-.28-1.86-.4-2.82-.4H19.46l5.8-13.46.06-.14c.3.1.58.24.88.36l4.42 1.86c2.46 1.02 4.18 2.08 5.22 3.36.2.24.36.46.5.72.18.28.32.56.4.86.08.18.14.36.18.52.54 1.68.22 3.74-.82 6.32Z" />
                    <path d="M43.043 28.397v3.9c0 .4-.02.8-.04 1.2-.38 6.98-4.28 10.5-11.68 10.5h-15.6c-.48 0-.96-.04-1.42-.1-6.36-.42-9.76-3.82-10.18-10.18-.06-.46-.1-.94-.1-1.42v-3.9c0-4.02 2.44-7.48 5.92-8.98 1.2-.5 2.48-.78 3.84-.78h19.52c.98 0 1.94.14 2.82.4 3.98 1.22 6.92 4.94 6.92 9.36Z" />
                    <path d="m13.42 11.056-3.5 8.36c-3.48 1.5-5.92 4.96-5.92 8.98v-5.86c0-5.68 4.04-10.42 9.42-11.48ZM43.037 22.535v5.86c0-4.4-2.92-8.14-6.92-9.34 1.04-2.6 1.34-4.64.84-6.34-.04-.18-.1-.36-.18-.52 3.72 1.92 6.26 5.86 6.26 10.34Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'proof-of-funds') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={49}
                height={48}
                fill="none"
                {...props}
            >
                <g
                    stroke={props.stroke || '#151515'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                >
                    <path d="M22.003 33.72v4.06c0 3.44-3.2 6.22-7.14 6.22-3.94 0-7.16-2.78-7.16-6.22v-4.06c0 3.44 3.2 5.88 7.16 5.88 3.94 0 7.14-2.46 7.14-5.88Z" />
                    <path d="M22 28.22c0 1-.28 1.92-.76 2.72-1.18 1.94-3.6 3.16-6.4 3.16-2.8 0-5.22-1.24-6.4-3.16-.48-.8-.76-1.72-.76-2.72 0-1.72.8-3.26 2.08-4.38 1.3-1.14 3.08-1.82 5.06-1.82 1.98 0 3.76.7 5.06 1.82C21.2 24.94 22 26.5 22 28.22Z" />
                    <path d="M22.003 28.22v5.5c0 3.44-3.2 5.88-7.14 5.88-3.94 0-7.16-2.46-7.16-5.88v-5.5c0-3.44 3.2-6.22 7.16-6.22 1.98 0 3.76.7 5.06 1.82 1.28 1.12 2.08 2.68 2.08 4.4ZM44.5 21.94v4.12c0 1.1-.88 2-2 2.04h-3.92c-2.16 0-4.14-1.58-4.32-3.74-.12-1.26.36-2.44 1.2-3.26.74-.76 1.76-1.2 2.88-1.2h4.16c1.12.04 2 .94 2 2.04Z" />
                    <path d="M4.5 21v-4c0-5.44 3.28-9.24 8.38-9.88.52-.08 1.06-.12 1.62-.12h18c.52 0 1.02.02 1.5.1 5.16.6 8.5 4.42 8.5 9.9v2.9h-4.16c-1.12 0-2.14.44-2.88 1.2-.84.82-1.32 2-1.2 3.26.18 2.16 2.16 3.74 4.32 3.74h3.92V31c0 6-4 10-10 10h-5" />
                </g>
            </svg>
        )
    }
    if (props.type === '3-cube') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#151515"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="m12.92 2.26 6.51 3.51c.76.41.76 1.58 0 1.99l-6.51 3.51c-.58.31-1.26.31-1.84 0L4.57 7.76c-.76-.41-.76-1.58 0-1.99l6.51-3.51c.58-.31 1.26-.31 1.84 0ZM3.61 10.13l6.05 3.03c.75.38 1.23 1.15 1.23 1.99v5.72c0 .83-.87 1.36-1.61.99l-6.05-3.03A2.238 2.238 0 0 1 2 16.84v-5.72c0-.83.87-1.36 1.61-.99ZM20.39 10.13l-6.05 3.03c-.75.38-1.23 1.15-1.23 1.99v5.72c0 .83.87 1.36 1.61.99l6.05-3.03c.75-.38 1.23-1.15 1.23-1.99v-5.72c0-.83-.87-1.36-1.61-.99Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'mini-transaction') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="M6.728 19.7c.82-.88 2.07-.81 2.79.15l1.01 1.35c.81 1.07 2.12 1.07 2.93 0l1.01-1.35c.72-.96 1.97-1.03 2.79-.15 1.78 1.9 3.23 1.27 3.23-1.39V7.04c0-4.03-.94-5.04-4.72-5.04h-7.56c-3.78 0-4.72 1.01-4.72 5.04V18.3c.01 2.67 1.47 3.29 3.24 1.4ZM9.25 10h5.5" />
                </g>
            </svg>
        )
    }

    if (props.type === 'support') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path
                        stroke="#5D5D5D"
                        d="M11.97 22c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Z"
                    />
                    <path
                        stroke="#5D5D5D"
                        d="M12 16.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM4.9 4.93l3.54 3.53M4.9 19.07l3.54-3.53M19.05 19.07l-3.54-3.53"
                    />
                    <path stroke="#212121" d="m19.05 4.93-3.54 3.53" />
                </g>
            </svg>
        )
    }

    if (props.type === 'notification') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g stroke="#5D5D5D" strokeMiterlimit={10} strokeWidth={1.286}>
                    <path
                        strokeLinecap="round"
                        d="M12.02 2.91c-3.31 0-6 2.69-6 6v2.89c0 .61-.26 1.54-.57 2.06L4.3 15.77c-.71 1.18-.22 2.49 1.08 2.93 4.31 1.44 8.96 1.44 13.27 0 1.21-.4 1.74-1.83 1.08-2.93l-1.15-1.91c-.3-.52-.56-1.45-.56-2.06V8.91c0-3.3-2.7-6-6-6Z"
                    />
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.87 3.2a6.754 6.754 0 0 0-3.7 0c.29-.74 1.01-1.26 1.85-1.26.84 0 1.56.52 1.85 1.26Z"
                    />
                    <path d="M15.02 19.06c0 1.65-1.35 3-3 3-.82 0-1.58-.34-2.12-.88a3.01 3.01 0 0 1-.88-2.12" />
                </g>
            </svg>
        )
    }

    if (props.type === 'search') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={25}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="M12 21a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19ZM22.5 22l-2-2" />
                </g>
            </svg>
        )
    }

    if (props.type === 'caret') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <path
                    stroke="#151515"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={1.5}
                    d="m19.92 8.95-6.52 6.52c-.77.77-2.03.77-2.8 0L4.08 8.95"
                />
            </svg>
        )
    }

    if (props.type === 'up-caret-light') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                {...props}
            >
                <g id="vuesax/linear/arrow-down">
                    <g id="arrow-down">
                        <path
                            id="Vector"
                            d="M3.05994 11.2871L7.94994 6.39711C8.52744 5.81961 9.47244 5.81961 10.0499 6.39711L14.9399 11.2871"
                            stroke="#5D5D5D"
                            strokeWidth="1.125"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                </g>
            </svg>
        )
    }

    if (props.type === 'down-caret-light') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                {...props}
            >
                <g id="vuesax/linear/arrow-down">
                    <g id="arrow-down">
                        <path
                            id="Vector"
                            d="M14.9401 6.71289L10.0501 11.6029C9.47256 12.1804 8.52756 12.1804 7.95006 11.6029L3.06006 6.71289"
                            stroke="#5D5D5D"
                            strokeWidth="1.125"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                </g>
            </svg>
        )
    }

    if (props.type === 'down-caret') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                {...props}
            >
                <g id="vuesax/linear/arrow-down">
                    <g id="arrow-down">
                        <path
                            id="Vector"
                            d="M14.9401 6.71289L10.0501 11.6029C9.47256 12.1804 8.52756 12.1804 7.95006 11.6029L3.06006 6.71289"
                            stroke="#151515"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                </g>
            </svg>
        )
    }

    if (props.type === 'boxes') {
        return (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5 10H7C9 10 10 9 10 7V5C10 3 9 2 7 2H5C3 2 2 3 2 5V7C2 9 3 10 5 10Z"
                    stroke="#151515"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M17 10H19C21 10 22 9 22 7V5C22 3 21 2 19 2H17C15 2 14 3 14 5V7C14 9 15 10 17 10Z"
                    stroke="#151515"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M17 22H19C21 22 22 21 22 19V17C22 15 21 14 19 14H17C15 14 14 15 14 17V19C14 21 15 22 17 22Z"
                    stroke="#151515"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M5 22H7C9 22 10 21 10 19V17C10 15 9 14 7 14H5C3 14 2 15 2 17V19C2 21 3 22 5 22Z"
                    stroke="#151515"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )
    }

    if (props.type === 'arrow-45deg') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={34}
                height={34}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={1.5}
                >
                    <path d="M22.981 19.574V10.99h-8.584M10.96 23.01l11.9-11.9" />
                </g>
            </svg>
        )
    }

    if (props.type === 'card-sm') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path
                        strokeMiterlimit={10}
                        d="M2 8.505h20M6 16.505h2M10.5 16.505h4"
                    />
                    <path d="M6.44 3.505h11.11c3.56 0 4.45.88 4.45 4.39v8.21c0 3.51-.89 4.39-4.44 4.39H6.44c-3.55.01-4.44-.87-4.44-4.38v-8.22c0-3.51.89-4.39 4.44-4.39Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'wallet-sm') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="m12.66 2.518-.03.07-2.9 6.73H6.88c-.68 0-1.33.14-1.92.39l1.75-4.18.04-.1.07-.16c.02-.06.04-.12.07-.17 1.31-3.03 2.79-3.72 5.77-2.58Z" />
                    <path d="M18.05 9.518c-.45-.14-.93-.2-1.41-.2H9.73l2.9-6.73.03-.07c.15.05.29.12.44.18l2.21.93c1.23.51 2.09 1.04 2.61 1.68.1.12.18.23.25.36.09.14.16.28.2.43.04.09.07.18.09.26.27.84.11 1.87-.41 3.16Z" />
                    <path d="M21.522 14.198v1.95c0 .2-.01.4-.02.6-.19 3.49-2.14 5.25-5.84 5.25h-7.8c-.24 0-.48-.02-.71-.05-3.18-.21-4.88-1.91-5.09-5.09-.03-.23-.05-.47-.05-.71v-1.95c0-2.01 1.22-3.74 2.96-4.49.6-.25 1.24-.39 1.92-.39h9.76c.49 0 .97.07 1.41.2 1.99.61 3.46 2.47 3.46 4.68Z" />
                    <path d="m6.71 5.528-1.75 4.18A4.894 4.894 0 0 0 2 14.198v-2.93c0-2.84 2.02-5.21 4.71-5.74ZM21.519 11.268v2.93c0-2.2-1.46-4.07-3.46-4.67.52-1.3.67-2.32.42-3.17-.02-.09-.05-.18-.09-.26 1.86.96 3.13 2.93 3.13 5.17Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'proof-of-funds-sm') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="M10.752 16.86v2.03c0 1.72-1.6 3.11-3.57 3.11-1.97 0-3.58-1.39-3.58-3.11v-2.03c0 1.72 1.6 2.94 3.58 2.94 1.97 0 3.57-1.23 3.57-2.94Z" />
                    <path d="M10.75 14.11c0 .5-.14.96-.38 1.36-.59.97-1.8 1.58-3.2 1.58-1.4 0-2.61-.62-3.2-1.58-.24-.4-.38-.86-.38-1.36 0-.86.4-1.63 1.04-2.19.65-.57 1.54-.91 2.53-.91.99 0 1.88.35 2.53.91.66.55 1.06 1.33 1.06 2.19Z" />
                    <path d="M10.752 14.11v2.75c0 1.72-1.6 2.94-3.57 2.94-1.97 0-3.58-1.23-3.58-2.94v-2.75c0-1.72 1.6-3.11 3.58-3.11.99 0 1.88.35 2.53.91.64.56 1.04 1.34 1.04 2.2ZM22 10.97v2.06c0 .55-.44 1-1 1.02h-1.96c-1.08 0-2.07-.79-2.16-1.87-.06-.63.18-1.22.6-1.63.37-.38.88-.6 1.44-.6H21c.56.02 1 .47 1 1.02Z" />
                    <path d="M2 10.5v-2c0-2.72 1.64-4.62 4.19-4.94.26-.04.53-.06.81-.06h9c.26 0 .51.01.75.05C19.33 3.85 21 5.76 21 8.5v1.45h-2.08c-.56 0-1.07.22-1.44.6-.42.41-.66 1-.6 1.63.09 1.08 1.08 1.87 2.16 1.87H21v1.45c0 3-2 5-5 5h-2.5" />
                </g>
            </svg>
        )
    }

    if (props.type === 'close') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={21}
                height={21}
                fill="none"
                {...props}
            >
                <g stroke="#5D5D5D" strokeLinecap="round" strokeWidth={3}>
                    <path d="m2 2 16.97 16.97M18.97 2 2 18.97" />
                </g>
            </svg>
        )
    }

    if (props.type === 'close-filled') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="none"
                {...props}
            >
                <path
                    fill="#D1D1D1"
                    d="M7.999 16a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8Zm3.085-10.058a.727.727 0 1 0-1.028-1.028L7.999 6.971 5.942 4.914a.727.727 0 0 0-1.028 1.028L6.97 8l-2.057 2.057a.728.728 0 1 0 1.028 1.029L8 9.028l2.057 2.058a.728.728 0 1 0 1.028-1.029L9.027 8l2.057-2.058Z"
                />
            </svg>
        )
    }

    if (props.type === 'person') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="M12 12a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM20.59 22c0-3.87-3.85-7-8.59-7s-8.59 3.13-8.59 7" />
                </g>
            </svg>
        )
    }

    if (props.type === 'logout') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <path fill="#151515" d="M17.44 14.62 20 12.06 17.44 9.5" />
                <path
                    stroke="#151515"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={1.5}
                    d="M17.44 14.62 20 12.06 17.44 9.5M9.76 12.06h10.17M11.76 20c-4.42 0-8-3-8-8s3.58-8 8-8"
                />
            </svg>
        )
    }

    if (props.type === 'settings') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={1.5}
                >
                    <path d="M5 10h2c2 0 3-1 3-3V5c0-2-1-3-3-3H5C3 2 2 3 2 5v2c0 2 1 3 3 3ZM17 10h2c2 0 3-1 3-3V5c0-2-1-3-3-3h-2c-2 0-3 1-3 3v2c0 2 1 3 3 3ZM17 22h2c2 0 3-1 3-3v-2c0-2-1-3-3-3h-2c-2 0-3 1-3 3v2c0 2 1 3 3 3ZM5 22h2c2 0 3-1 3-3v-2c0-2-1-3-3-3H5c-2 0-3 1-3 3v2c0 2 1 3 3 3Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'close-sm') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={11}
                height={11}
                fill="none"
                {...props}
            >
                <g stroke="#5D5D5D" strokeLinecap="round" strokeWidth={1.5}>
                    <path d="m1 1 8.485 8.485M9.485 1 1 9.485" />
                </g>
            </svg>
        )
    }

    if (props.type === 'back') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <path
                    stroke="#EEE"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    d="M15 19.92 8.48 13.4c-.77-.77-.77-2.03 0-2.8L15 4.08"
                />
            </svg>
        )
    }

    if (props.type === 'next') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    d="m8.91 19.92 6.52-6.52c.77-.77.77-2.03 0-2.8L8.91 4.08"
                />
            </svg>
        )
    }

    if (props.type === 'yellow-check') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={159}
                height={145}
                fill="none"
                {...props}
            >
                <rect
                    width={51.543}
                    height={51.543}
                    x={14.869}
                    y={0.792}
                    fill="#FFFD86"
                    opacity={0.5}
                    rx={10}
                />
                <rect
                    width={29.359}
                    height={29.359}
                    x={128.82}
                    y={33.663}
                    fill="#FFFD86"
                    opacity={0.5}
                    rx={10}
                />
                <rect
                    width={31.533}
                    height={31.533}
                    x={0.82}
                    y={75.663}
                    fill="#FEFFC1"
                    opacity={0.5}
                    rx={8}
                />
                <rect
                    width={36.593}
                    height={36.593}
                    x={115.107}
                    y={107.614}
                    fill="#FEFFC1"
                    opacity={0.5}
                    rx={8}
                />
                <circle cx={84.297} cy={79.168} r={60} fill="gold" />
                <g filter="url(#a)">
                    <path
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={8}
                        d="m61.58 82.413 12.98 12.98 32.452-32.451"
                    />
                </g>
                <defs>
                    <filter
                        id="a"
                        width={113.432}
                        height={100.451}
                        x={27.58}
                        y={32.942}
                        colorInterpolationFilters="sRGB"
                        filterUnits="userSpaceOnUse"
                    >
                        <feFlood floodOpacity={0} result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            result="hardAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset dy={4} />
                        <feGaussianBlur stdDeviation={15} />
                        <feColorMatrix values="0 0 0 0 0.290196 0 0 0 0 0.227451 0 0 0 0 1 0 0 0 0.3 0" />
                        <feBlend
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_1178_9077"
                        />
                        <feBlend
                            in="SourceGraphic"
                            in2="effect1_dropShadow_1178_9077"
                            result="shape"
                        />
                    </filter>
                </defs>
            </svg>
        )
    }

    if (props.type === 'checked-circle') {
        return (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <g id="vuesax/linear/tick-circle">
                    <g id="tick-circle">
                        <path
                            id="Vector"
                            d="M8.00016 14.6666C11.6668 14.6666 14.6668 11.6666 14.6668 7.99998C14.6668 4.33331 11.6668 1.33331 8.00016 1.33331C4.3335 1.33331 1.3335 4.33331 1.3335 7.99998C1.3335 11.6666 4.3335 14.6666 8.00016 14.6666Z"
                            stroke={props.stroke || '#888888'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            id="Vector_2"
                            d="M5.1665 8.00001L7.05317 9.88668L10.8332 6.11334"
                            stroke={props.stroke || '#888888'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                </g>
            </svg>
        )
    }

    if (props.type === 'drag-drop') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={32}
                height={32}
                fill="none"
                {...props}
            >
                <g
                    stroke="#212121"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="M12 22.667v-8l-2.666 2.666M12 14.667l2.667 2.666" />
                    <path d="M29.333 13.333V20c0 6.667-2.666 9.333-9.333 9.333h-8c-6.667 0-9.334-2.666-9.334-9.333v-8c0-6.667 2.667-9.333 9.334-9.333h6.666" />
                    <path d="M29.333 13.333H24c-4 0-5.334-1.333-5.334-5.333V2.667l10.667 10.666Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'kyc') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="M12 12a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM3.41 22c0-3.87 3.85-7 8.59-7 .96 0 1.89.13 2.76.37" />
                    <path
                        strokeMiterlimit={10}
                        d="M22 18c0 .75-.21 1.46-.58 2.06-.21.36-.48.68-.79.94-.7.63-1.62 1-2.63 1a3.97 3.97 0 0 1-3.42-1.94A3.92 3.92 0 0 1 14 18c0-1.26.58-2.39 1.5-3.12A3.999 3.999 0 0 1 22 18Z"
                    />
                    <path d="m16.44 18 .99.99 2.13-1.97" />
                </g>
            </svg>
        )
    }

    if (props.type === 'security') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path
                        strokeMiterlimit={10}
                        d="M19.79 14.93a7.575 7.575 0 0 1-7.6 1.87l-4.71 4.7c-.34.35-1.01.56-1.49.49l-2.18-.3c-.72-.1-1.39-.78-1.5-1.5l-.3-2.18c-.07-.48.16-1.15.49-1.49l4.7-4.7c-.8-2.6-.18-5.55 1.88-7.6 2.95-2.95 7.74-2.95 10.7 0 2.96 2.95 2.96 7.76.01 10.71ZM6.89 17.49l2.3 2.3"
                    />
                    <path d="M14.5 11a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'preference') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="M11.97 22c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Z" />
                    <path d="M7.21 16A5.831 5.831 0 0 0 12 18.5c1.98 0 3.73-.99 4.79-2.5" />
                </g>
            </svg>
        )
    }

    if (props.type === 'image') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#212121"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="M6.76 22h10.48c2.76 0 3.86-1.69 3.99-3.75l.52-8.26A3.753 3.753 0 0 0 18 6c-.61 0-1.17-.35-1.45-.89l-.72-1.45C15.37 2.75 14.17 2 13.15 2h-2.29c-1.03 0-2.23.75-2.69 1.66l-.72 1.45C7.17 5.65 6.61 6 6 6 3.83 6 2.11 7.83 2.25 9.99l.52 8.26C2.89 20.31 4 22 6.76 22ZM10.5 8h3" />
                    <path d="M12 18c1.79 0 3.25-1.46 3.25-3.25S13.79 11.5 12 11.5s-3.25 1.46-3.25 3.25S10.21 18 12 18Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'clock') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="M22 12c0 5.52-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2s10 4.48 10 10Z" />
                    <path d="m15.71 15.18-3.1-1.85c-.54-.32-.98-1.09-.98-1.72v-4.1" />
                </g>
            </svg>
        )
    }

    if (props.type === 'calendar') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path
                        strokeMiterlimit={10}
                        strokeWidth={1.5}
                        d="M8 2v3M16 2v3M3.5 9.09h17M21 8.5V17c0 3-1.5 5-5 5H8c-3.5 0-5-2-5-5V8.5c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5Z"
                    />
                    <path
                        strokeWidth={2}
                        d="M11.995 13.7h.01M8.294 13.7h.01M8.294 16.7h.01"
                    />
                </g>
            </svg>
        )
    }

    if (props.type === 'silver-agent') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g stroke="#00C06A" strokeWidth={1.5}>
                    <path d="M10.409 2.652a2.25 2.25 0 0 1 3.182 0l7.757 7.757a2.25 2.25 0 0 1 0 3.182l-7.757 7.757a2.25 2.25 0 0 1-3.182 0l-7.757-7.757a2.25 2.25 0 0 1 0-3.182l7.757-7.757Z" />
                    <path d="M11.824 6.291a.25.25 0 0 1 .354 0l5.533 5.533a.25.25 0 0 1 0 .354l-5.533 5.533a.25.25 0 0 1-.354 0l-5.533-5.533a.25.25 0 0 1 0-.354l5.533-5.533Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'gold-agent') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g stroke="#B0B0B0" strokeWidth={1.5}>
                    <path d="M10.678 1.888a2.25 2.25 0 0 1 2.644 0l7.887 5.73a2.25 2.25 0 0 1 .817 2.515l-3.012 9.27a2.25 2.25 0 0 1-2.14 1.555H7.126a2.25 2.25 0 0 1-2.14-1.554l-3.012-9.271a2.25 2.25 0 0 1 .818-2.516l7.886-5.73Z" />
                    <path d="M11.854 6.088a.25.25 0 0 1 .294 0l5.432 3.946a.25.25 0 0 1 .09.28l-2.074 6.385a.25.25 0 0 1-.238.173H8.644a.25.25 0 0 1-.238-.173l-2.075-6.386a.25.25 0 0 1 .091-.28l5.432-3.945Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'diamond-agent') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g stroke="#B0B0B0" strokeWidth={1.5}>
                    <path d="M11.139 1.168a2.25 2.25 0 0 1 1.722 0l6.19 2.564c.55.228.989.666 1.217 1.218l2.564 6.189a2.25 2.25 0 0 1 0 1.722l-2.564 6.19a2.25 2.25 0 0 1-1.218 1.217l-6.189 2.564a2.25 2.25 0 0 1-1.722 0l-6.19-2.564a2.25 2.25 0 0 1-1.217-1.218l-2.564-6.189a2.25 2.25 0 0 1 0-1.722l2.564-6.19A2.25 2.25 0 0 1 4.95 3.733l6.189-2.564Z" />
                    <path d="M11.905 5.905a.25.25 0 0 1 .192 0l4.147 1.718a.25.25 0 0 1 .135.135l1.718 4.147a.25.25 0 0 1 0 .192l-1.718 4.147a.25.25 0 0 1-.135.135l-4.147 1.718a.25.25 0 0 1-.192 0l-4.147-1.718a.25.25 0 0 1-.135-.135l-1.718-4.147a.25.25 0 0 1 0-.192l1.718-4.147a.25.25 0 0 1 .135-.135l4.147-1.718Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'hamburger') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
                {...props}
            >
                <path d="M0 96c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zm448 160c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h384c17.7 0 32 14.3 32 32z" />
            </svg>
        )
    }

    if (props.type === 'play-video') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={32}
                height={32}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                >
                    <path d="M15.96 29.333c7.364 0 13.334-5.97 13.334-13.334 0-7.363-5.97-13.333-13.334-13.333S2.627 8.636 2.627 15.999c0 7.364 5.97 13.334 13.333 13.334Z" />
                    <path
                        strokeMiterlimit={10}
                        d="M11.654 16.308V14.08c0-2.774 1.96-3.907 4.36-2.52l1.933 1.12 1.934 1.12c2.4 1.386 2.4 3.653 0 5.04l-1.934 1.12-1.933 1.12c-2.4 1.387-4.36.253-4.36-2.52v-2.253Z"
                    />
                </g>
            </svg>
        )
    }

    if (props.type === 'ngn-flag') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={12}
                fill="none"
                {...props}
            >
                <mask
                    id="a"
                    width={16}
                    height={12}
                    x={0}
                    y={0}
                    maskUnits="userSpaceOnUse"
                    style={{
                        maskType: 'luminance',
                    }}
                >
                    <path fill="#fff" d="M0 0h16v12H0z" />
                </mask>
                <g fillRule="evenodd" clipRule="evenodd" mask="url(#a)">
                    <path fill="#F7FCFF" d="M0 0h16v12H0V0Z" />
                    <path fill="#093" d="M11 0h5v12h-5V0ZM0 0h5v12H0V0Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'inactive-radio') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <path
                    stroke="#B0B0B0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10Z"
                />
            </svg>
        )
    }

    if (props.type === 'active-radio') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={25}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#151515"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="M12.667 22c5.5 0 10-4.5 10-10s-4.5-10-10-10-10 4.5-10 10 4.5 10 10 10Z" />
                    <path d="m8.417 12 2.83 2.83 5.67-5.66" />
                </g>
            </svg>
        )
    }

    if (props.type === 'approved-stat') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={25}
                fill="none"
                {...props}
            >
                <g
                    stroke="#00C06A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="M9 22.6h6c5 0 7-2 7-7v-6c0-5-2-7-7-7H9c-5 0-7 2-7 7v6c0 5 2 7 7 7Z" />
                    <path d="m7.75 12.6 2.83 2.83 5.67-5.66" />
                </g>
            </svg>
        )
    }

    if (props.type === 'ongoing-stat') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={25}
                fill="none"
                {...props}
            >
                <g
                    stroke="#7A30AB"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="m16.5 10.299-4.2 4.2-1.6-2.4-3.2 3.2" />
                    <path d="M14.5 10.299h2v2" />
                    <path d="M9 22.799h6c5 0 7-2 7-7v-6c0-5-2-7-7-7H9c-5 0-7 2-7 7v6c0 5 2 7 7 7Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'doc-required-stat') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#D19E00"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="M9 17v-6l-2 2M9 11l2 2" />
                    <path d="M22 10v5c0 5-2 7-7 7H9c-5 0-7-2-7-7V9c0-5 2-7 7-7h5" />
                    <path d="M22 10h-4c-3 0-4-1-4-4V2l8 8Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'download-doc') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#00C06A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="M9 11v6l2-2M9 17l-2-2" />
                    <path d="M22 10v5c0 5-2 7-7 7H9c-5 0-7-2-7-7V9c0-5 2-7 7-7h5" />
                    <path d="M22 10h-4c-3 0-4-1-4-4V2l8 8Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'download') {
        return (
            <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                {...props}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.5 3C13.5 2.44772 13.0523 2 12.5 2C11.9477 2 11.5 2.44772 11.5 3V15.865L7.14018 12.2318C6.7159 11.8782 6.08534 11.9355 5.73177 12.3598C5.37821 12.7841 5.43553 13.4147 5.85981 13.7682L11.8598 18.7682C12.2307 19.0773 12.7693 19.0773 13.1402 18.7682L19.1402 13.7682C19.5645 13.4147 19.6218 12.7841 19.2682 12.3598C18.9147 11.9355 18.2841 11.8782 17.8598 12.2318L13.5 15.865V3ZM5.5 20C4.94772 20 4.5 20.4477 4.5 21C4.5 21.5523 4.94772 22 5.5 22H19.5C20.0523 22 20.5 21.5523 20.5 21C20.5 20.4477 20.0523 20 19.5 20H5.5Z"
                    fill="#5D5D5D"
                />
            </svg>
        )
    }

    if (props.type === 'rejected-stat') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={25}
                fill="none"
                {...props}
            >
                <g
                    stroke="#FC2642"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="m9.17 15.03 5.66-5.66M14.83 15.03 9.17 9.37M9 22.2h6c5 0 7-2 7-7v-6c0-5-2-7-7-7H9c-5 0-7 2-7 7v6c0 5 2 7 7 7Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'disbursed-stat') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={25}
                fill="none"
                {...props}
            >
                <g
                    stroke="#413FE5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="M19.5 12.9a2.5 2.5 0 0 1 2.5-2.5v-1c0-4-1-5-5-5H7c-4 0-5 1-5 5v.5a2.5 2.5 0 0 1 0 5v.5c0 4 1 5 5 5h10c4 0 5-1 5-5a2.5 2.5 0 0 1-2.5-2.5Z" />
                    <path strokeDasharray="5 5" d="M10 4.4v16" />
                </g>
            </svg>
        )
    }

    if (props.type === 'bin') {
        return (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g id="vuesax/linear/trash">
                    <g id="trash">
                        <path
                            id="Vector"
                            d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                            stroke="#5D5D5D"
                            strokeWidth="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            id="Vector_2"
                            d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                            stroke="#5D5D5D"
                            strokeWidth="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            id="Vector_3"
                            d="M18.8499 9.14001L18.1999 19.21C18.0899 20.78 17.9999 22 15.2099 22H8.7899C5.9999 22 5.9099 20.78 5.7999 19.21L5.1499 9.14001"
                            stroke="#5D5D5D"
                            strokeWidth="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            id="Vector_4"
                            d="M10.3301 16.5H13.6601"
                            stroke="#5D5D5D"
                            strokeWidth="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            id="Vector_5"
                            d="M9.5 12.5H14.5"
                            stroke="#5D5D5D"
                            strokeWidth="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </g>
                </g>
            </svg>
        )
    }

    if (props.type === 'half-loading') {
        return (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g id="Group 155875">
                    <path
                        id="Ellipse 11"
                        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM2.4 12C2.4 17.3019 6.69807 21.6 12 21.6C17.3019 21.6 21.6 17.3019 21.6 12C21.6 6.69807 17.3019 2.4 12 2.4C6.69807 2.4 2.4 6.69807 2.4 12Z"
                        fill="#EDFFF7"
                    />
                    <path
                        id="Ellipse 10"
                        d="M22.8 12C23.4627 12 24.0062 12.5386 23.9401 13.198C23.665 15.9406 22.4515 18.519 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.54845 18.519 0.335015 15.9406 0.0599168 13.198C-0.00622779 12.5386 0.537258 12 1.2 12C1.86274 12 2.39226 12.5393 2.47487 13.1969C2.73931 15.3017 3.69588 17.2723 5.21178 18.7882C7.01213 20.5886 9.45392 21.6 12 21.6C14.5461 21.6 16.9879 20.5886 18.7882 18.7882C20.3041 17.2723 21.2607 15.3017 21.5251 13.1969C21.6077 12.5393 22.1373 12 22.8 12Z"
                        fill="#00E785"
                    />
                </g>
            </svg>
        )
    }

    if (props.type === 'plus') {
        return (
            <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g id="vuesax/linear/add-square">
                    <g id="add-square">
                        <path
                            id="Vector"
                            d="M8.5 12H16.5"
                            stroke="#5D5D5D"
                            strokeWidth="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            id="Vector_2"
                            d="M12.5 16V8"
                            stroke="#5D5D5D"
                            strokeWidth="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            id="Vector_3"
                            d="M9.5 22H15.5C20.5 22 22.5 20 22.5 15V9C22.5 4 20.5 2 15.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22Z"
                            stroke="#5D5D5D"
                            strokeWidth="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </g>
                </g>
            </svg>
        )
    }

    if (props.type === 'percentage') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="m8.57 15.27 6.54-6.54M8.98 10.37a1.23 1.23 0 1 0 0-2.46 1.23 1.23 0 0 0 0 2.46ZM15.519 16.09a1.23 1.23 0 1 0 0-2.46 1.23 1.23 0 0 0 0 2.46Z" />
                    <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z" />
                </g>
            </svg>
        )
    }

    if (props.type === 'success-icon') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={159}
                height={145}
                fill="none"
                {...props}
            >
                <rect
                    width={51.543}
                    height={51.543}
                    x={14.867}
                    y={0.793}
                    fill="#FFFD86"
                    opacity={0.5}
                    rx={10}
                />
                <rect
                    width={29.359}
                    height={29.359}
                    x={128.82}
                    y={33.663}
                    fill="#FFFD86"
                    opacity={0.5}
                    rx={10}
                />
                <rect
                    width={31.533}
                    height={31.533}
                    x={0.82}
                    y={75.663}
                    fill="#FEFFC1"
                    opacity={0.5}
                    rx={8}
                />
                <rect
                    width={36.593}
                    height={36.593}
                    x={115.109}
                    y={107.614}
                    fill="#FEFFC1"
                    opacity={0.5}
                    rx={8}
                />
                <circle cx={84.297} cy={79.168} r={60} fill="gold" />
                <g filter="url(#a)">
                    <path
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={8}
                        d="m61.578 82.413 12.98 12.98 32.452-32.451"
                    />
                </g>
                <defs>
                    <filter
                        id="a"
                        width={113.434}
                        height={100.451}
                        x={27.578}
                        y={32.942}
                        colorInterpolationFilters="sRGB"
                        filterUnits="userSpaceOnUse"
                    >
                        <feFlood floodOpacity={0} result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            result="hardAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset dy={4} />
                        <feGaussianBlur stdDeviation={15} />
                        <feColorMatrix values="0 0 0 0 0.290196 0 0 0 0 0.227451 0 0 0 0 1 0 0 0 0.3 0" />
                        <feBlend
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_2909_20235"
                        />
                        <feBlend
                            in="SourceGraphic"
                            in2="effect1_dropShadow_2909_20235"
                            result="shape"
                        />
                    </filter>
                </defs>
            </svg>
        )
    }

    if (props.type === 'phone') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={25}
                fill="none"
                {...props}
            >
                <path
                    stroke="#151515"
                    strokeMiterlimit={10}
                    strokeWidth={1.5}
                    d="M21.97 18.83c0 .36-.08.73-.25 1.09-.17.36-.39.7-.68 1.02-.49.54-1.03.93-1.64 1.18-.6.25-1.25.38-1.95.38-1.02 0-2.11-.24-3.26-.73s-2.3-1.15-3.44-1.98a28.75 28.75 0 0 1-3.28-2.8 28.414 28.414 0 0 1-2.79-3.27c-.82-1.14-1.48-2.28-1.96-3.41C2.24 9.17 2 8.08 2 7.04c0-.68.12-1.33.36-1.93.24-.61.62-1.17 1.15-1.67.64-.63 1.34-.94 2.08-.94.28 0 .56.06.81.18.26.12.49.3.67.56l2.32 3.27c.18.25.31.48.4.7.09.21.14.42.14.61 0 .24-.07.48-.21.71-.13.23-.32.47-.56.71l-.76.79c-.11.11-.16.24-.16.4 0 .08.01.15.03.23.03.08.06.14.08.2.18.33.49.76.93 1.28.45.52.93 1.05 1.45 1.58.54.53 1.06 1.02 1.59 1.47.52.44.95.74 1.29.92.05.02.11.05.18.08.08.03.16.04.25.04.17 0 .3-.06.41-.17l.76-.75c.25-.25.49-.44.72-.56.23-.14.46-.21.71-.21.19 0 .39.04.61.13.22.09.45.22.7.39l3.31 2.35c.26.18.44.39.55.64.1.25.16.5.16.78Z"
                />
            </svg>
        )
    }

    if (props.type === 'email-sm') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={25}
                fill="none"
                {...props}
            >
                <g
                    stroke="#151515"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={1.5}
                >
                    <path d="M17 21H7c-3 0-5-1.5-5-5V9c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v7c0 3.5-2 5-5 5Z" />
                    <path d="M17 9.5 13.87 12c-1.03.82-2.72.82-3.75 0L7 9.5" />
                </g>
            </svg>
        )
    }

    if (props.type === 'category') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={17}
                fill="none"
                {...props}
            >
                <g
                    stroke="#5D5D5D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                >
                    <path d="M7.98 15.167a6.667 6.667 0 1 0 0-13.333 6.667 6.667 0 0 0 0 13.333Z" />
                    <path d="M8 11.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM3.268 3.787l2.36 2.353M3.268 13.213l2.36-2.353M12.7 13.213l-2.36-2.353M12.7 3.787 10.34 6.14" />
                </g>
            </svg>
        )
    }

    if (props.type === 'sme') {
        return (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                {...props}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9 2H15C20 2 22 4 22 9V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2Z"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M8.57007 15.27L15.11 8.72998"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M8.98001 10.3699C9.65932 10.3699 10.21 9.81923 10.21 9.13992C10.21 8.46061 9.65932 7.90991 8.98001 7.90991C8.3007 7.90991 7.75 8.46061 7.75 9.13992C7.75 9.81923 8.3007 10.3699 8.98001 10.3699Z"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M15.52 16.0899C16.1993 16.0899 16.75 15.5392 16.75 14.8599C16.75 14.1806 16.1993 13.6299 15.52 13.6299C14.8407 13.6299 14.29 14.1806 14.29 14.8599C14.29 15.5392 14.8407 16.0899 15.52 16.0899Z"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )
    }

    if (props.type === 'user-avatar') {
        return (
            <svg
                width="76"
                height="76"
                viewBox="0 0 76 76"
                fill="none"
                {...props}
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.791667"
                    y="0.791667"
                    width="74.4167"
                    height="74.4167"
                    rx="37.2083"
                    stroke="#D1D1D1"
                    stroke-width="1.58333"
                />
                <path
                    d="M38.0002 38.0001C42.3724 38.0001 45.9168 34.4557 45.9168 30.0834C45.9168 25.7112 42.3724 22.1667 38.0002 22.1667C33.6279 22.1667 30.0835 25.7112 30.0835 30.0834C30.0835 34.4557 33.6279 38.0001 38.0002 38.0001Z"
                    stroke="#5D5D5D"
                    stroke-width="2.03571"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M51.6008 53.8333C51.6008 47.7058 45.505 42.75 38 42.75C30.495 42.75 24.3992 47.7058 24.3992 53.8333"
                    stroke="#5D5D5D"
                    stroke-width="2.03571"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )
    }

    if (props.type === 'spinner') {
        return (
            <svg
                class="animate-spin w-full h-full fill-current"
                viewBox="3 3 18 18"
                {...props}
            >
                <path
                    class="opacity-20"
                    d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                />
                <path d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z" />
            </svg>
        )
    }

    if (props.type === 'user') {
        return (
            <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M19.9974 20.0007C24.5998 20.0007 28.3307 16.2697 28.3307 11.6673C28.3307 7.06494 24.5998 3.33398 19.9974 3.33398C15.395 3.33398 11.6641 7.06494 11.6641 11.6673C11.6641 16.2697 15.395 20.0007 19.9974 20.0007Z"
                    stroke="#151515"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M34.313 36.6667C34.313 30.2167 27.8963 25 19.9963 25C12.0963 25 5.67969 30.2167 5.67969 36.6667"
                    stroke="#151515"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )
    }

    if (props.type === 'student') {
        return (
            <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M16.7505 4.21746L6.71719 10.7675C3.50052 12.8675 3.50052 17.5675 6.71719 19.6675L16.7505 26.2175C18.5505 27.4008 21.5172 27.4008 23.3172 26.2175L33.3005 19.6675C36.5005 17.5675 36.5005 12.8841 33.3005 10.7841L23.3172 4.23413C21.5172 3.03413 18.5505 3.03413 16.7505 4.21746Z"
                    stroke="#151515"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M9.38385 21.8008L9.36719 29.6174C9.36719 31.7341 11.0005 34.0008 13.0005 34.6674L18.3172 36.4341C19.2339 36.7341 20.7505 36.7341 21.6839 36.4341L27.0005 34.6674C29.0005 34.0008 30.6339 31.7341 30.6339 29.6174V21.8841"
                    stroke="#151515"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M35.6641 25V15"
                    stroke="#151515"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )
    }

    if (props.type === 'agent') {
        return (
            <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M3.33594 36.666H36.6693"
                    stroke="#151515"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M20 3.33398C22.6667 4.40065 25.6667 4.40065 28.3333 3.33398V8.33398C25.6667 9.40065 22.6667 9.40065 20 8.33398V3.33398Z"
                    stroke="#151515"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M20 8.33398V13.334"
                    stroke="#151515"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M28.3307 13.334H11.6641C8.33073 13.334 6.66406 15.0007 6.66406 18.334V36.6673H33.3307V18.334C33.3307 15.0007 31.6641 13.334 28.3307 13.334Z"
                    stroke="#212121"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M7.63281 20H32.3661"
                    stroke="#212121"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M13.3203 20V36.6667"
                    stroke="#212121"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                />
                <path
                    d="M19.9844 20V36.6667"
                    stroke="#212121"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                />
                <path
                    d="M26.6484 20V36.6667"
                    stroke="#212121"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                />
            </svg>
        )
    }
    if (props.type === 'partner') {
        return (
            <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M20.6193 3.58242L35.6193 9.58238C36.2026 9.81571 36.6693 10.5157 36.6693 11.1324V16.6657C36.6693 17.5824 35.9193 18.3324 35.0026 18.3324H5.0026C4.08594 18.3324 3.33594 17.5824 3.33594 16.6657V11.1324C3.33594 10.5157 3.80261 9.81571 4.38595 9.58238L19.3859 3.58242C19.7193 3.44909 20.2859 3.44909 20.6193 3.58242Z"
                    stroke="#151515"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M36.6693 36.6667H3.33594V31.6667C3.33594 30.75 4.08594 30 5.0026 30H35.0026C35.9193 30 36.6693 30.75 36.6693 31.6667V36.6667Z"
                    stroke="#151515"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M6.66406 30.0007V18.334"
                    stroke="#151515"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M13.3359 30.0007V18.334"
                    stroke="#212121"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M20 30.0007V18.334"
                    stroke="#212121"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M26.6641 30.0007V18.334"
                    stroke="#212121"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M33.3359 30.0007V18.334"
                    stroke="#212121"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M1.66406 36.666H38.3307"
                    stroke="#212121"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M20 14.166C21.3807 14.166 22.5 13.0467 22.5 11.666C22.5 10.2853 21.3807 9.16602 20 9.16602C18.6193 9.16602 17.5 10.2853 17.5 11.666C17.5 13.0467 18.6193 14.166 20 14.166Z"
                    stroke="#212121"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )
    }

    if (props.type === 'add') {
        return (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 12H18"
                    stroke={props.color || '#212121'}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M12 18V6"
                    stroke={props.color || '#212121'}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )
    }

    if (props.type === 'courthouse') {
        return (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2 22H22"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M12 2C13.6 2.64 15.4 2.64 17 2V5C15.4 5.64 13.6 5.64 12 5V2Z"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M12 5V8"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M17 8H7C5 8 4 9 4 11V22H20V11C20 9 19 8 17 8Z"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M4.57812 12H19.4181"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M7.99219 12V22"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                />
                <path
                    d="M11.9922 12V22"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                />
                <path
                    d="M15.9922 12V22"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                />
            </svg>
        )
    }

    if (props.type === 'upload') {
        return (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.0625 7.6875L12 3.75L15.9375 7.6875"
                    stroke="#191C1F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M12 14.25V3.75"
                    stroke="#191C1F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M20.25 14.25V19.5C20.25 19.6989 20.171 19.8897 20.0303 20.0303C19.8897 20.171 19.6989 20.25 19.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V14.25"
                    stroke="#191C1F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )
    }

    if (props.type === 'user-icon') {
        return (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.4375 19.0493L15.9575 20.5693L18.9975 17.5293"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M12.1625 10.87C12.0625 10.86 11.9425 10.86 11.8325 10.87C9.45254 10.79 7.56254 8.84 7.56254 6.44C7.55254 3.99 9.54254 2 11.9925 2C14.4425 2 16.4325 3.99 16.4325 6.44C16.4325 8.84 14.5325 10.79 12.1625 10.87Z"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M11.9891 21.8097C10.1691 21.8097 8.35906 21.3497 6.97906 20.4297C4.55906 18.8097 4.55906 16.1697 6.97906 14.5597C9.72906 12.7197 14.2391 12.7197 16.9891 14.5597"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )
    }

    if (props.type === 'percent') {
        return (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9 2H15C20 2 22 4 22 9V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2Z"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M8.57031 15.2704L15.1103 8.73047"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M8.98001 10.3701C9.65932 10.3701 10.21 9.81948 10.21 9.14017C10.21 8.46086 9.65932 7.91016 8.98001 7.91016C8.3007 7.91016 7.75 8.46086 7.75 9.14017C7.75 9.81948 8.3007 10.3701 8.98001 10.3701Z"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M15.519 16.0909C16.1984 16.0909 16.7491 15.5402 16.7491 14.8608C16.7491 14.1815 16.1984 13.6309 15.519 13.6309C14.8397 13.6309 14.2891 14.1815 14.2891 14.8608C14.2891 15.5402 14.8397 16.0909 15.519 16.0909Z"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )
    }

    if (props.type === 'analytics') {
        return (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7 10.7402V13.9402"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M12 9V15.68"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M17 10.7402V13.9402"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )
    }

    if (props.type === 'ticket') {
        return (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.72437 19.7C7.54437 18.82 8.79437 18.89 9.51437 19.85L10.5244 21.2C11.3344 22.27 12.6444 22.27 13.4544 21.2L14.4644 19.85C15.1844 18.89 16.4344 18.82 17.2544 19.7C19.0344 21.6 20.4844 20.97 20.4844 18.31V7.04C20.4844 3.01 19.5444 2 15.7644 2H8.20438C4.42438 2 3.48438 3.01 3.48438 7.04V18.3C3.49437 20.97 4.95437 21.59 6.72437 19.7Z"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M9.25 10H14.75"
                    stroke="#5D5D5D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )
    }

    return null
}

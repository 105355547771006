import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { pageAnimationConfig } from "../../../utils";
import { Button, Icon, Input, Text } from "../../atoms";
import { africredColors } from "../../../theme/colors";
import profilePicture from "../../../assets/pngs/dp.png";
import { TimelineIndicator } from "../../organisms/TimelineIndicator";

const Wrapper = styled(motion.div)``;
const WhiteArea = styled.div`
  display: flex;
  padding: 48px 96px;
  gap: 10px;
  border-radius: 32px;
  background: ${africredColors.neutrals.white[10]};
  margin-top: 28px;
`;
const InputArea = styled.div`
  border-left: 1px solid ${africredColors.neutrals.night[200]};
  padding: 0 56px;
`;
const InputRow = styled.div`
  display: flex;
  gap: 12px;
`;
const InputRowWrapper = styled.div`
  border-top: 1px solid ${africredColors.neutrals.night[200]};
  padding: 32px 0;
`;
const ProfilePictureRow = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 32px;
  align-items: center;
`;
const ConfirmedInformation = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const agentLevels = [
  {
    title: "Sliver",
    icon: <Icon type="silver-agent" />,
    content: "Mak payments with AfriCred standard processing fee",
    color: "pink",
  },
  {
    title: "Gold",
    icon: <Icon type="gold-agent" />,
    content:
      "Make over 30 transactions in a week and enjoy 0.5% each time you make payment.",
    gold: "gold",
  },
  {
    title: "Diamond",
    icon: <Icon type="diamond-agent" />,
    content:
      "Make over 30 transactions in a week and enjoy 0.5% each time you make payment.",
    color: "purple",
  },
];

export const AgentProfileSettings = () => {
  return (
    <Wrapper {...pageAnimationConfig}>
      <Text type="h3" weight="medium">
        Profile
      </Text>
      <WhiteArea>
        <ConfirmedInformation className="w-2/6">
          <div>
            <Text type="h4" weight="bold" margin="0 0 1.5rem 0">
              Agent Level
            </Text>
            <div className="ps-2">
              <TimelineIndicator data={agentLevels} />
            </div>
          </div>

          <div className="mt-5">
            <Text type="h5" weight="bold" margin="0 0 1rem 0">
              Richard’s confirmed information
            </Text>
            <div className="flex items-center gap-4">
              <Icon
                type="checked-circle"
                width={25}
                height={25}
                stroke={africredColors.primary.green[600]}
              />
              <Text type="p">Email address</Text>
            </div>
            <div className="flex items-center gap-4 mt-4">
              <Icon
                type="checked-circle"
                width={25}
                height={25}
                stroke={africredColors.primary.green[600]}
              />
              <Text type="p">Phone number</Text>
            </div>
          </div>
        </ConfirmedInformation>

        <InputArea className="w-3/5 xl:w-3/6">
          <ProfilePictureRow>
            <img src={profilePicture} alt="profile-img" />
            <Button
              size="md"
              border={`1px solid ${africredColors.neutrals.night[950]}`}
            >
              <Icon type="image" />
              <Text type="p" weight="bold">
                Upload photo
              </Text>
            </Button>
          </ProfilePictureRow>
          <InputRowWrapper>
            <Text type="h5" weight="medium" margin="0 0 1rem 0">
              Email
            </Text>
            <InputRow>
              <Input size="md" icon={<Icon type="email" />} type="email" />
              <Button
                size="md"
                border={`1px solid ${africredColors.neutrals.night[950]}`}
              >
                <Text type="p" weight="medium">
                  Update
                </Text>
              </Button>
            </InputRow>
          </InputRowWrapper>
          <InputRowWrapper>
            <Text type="h5" weight="medium" margin="0 0 1rem 0">
              Phone number
            </Text>
            <InputRow>
              <Input size="md" type="tel" />
              <Button
                size="md"
                border={`1px solid ${africredColors.neutrals.night[950]}`}
              >
                <Text type="p" weight="medium">
                  Update
                </Text>
              </Button>
            </InputRow>
          </InputRowWrapper>
        </InputArea>
      </WhiteArea>
    </Wrapper>
  );
};

import React, { useEffect, useState } from 'react'
import { africredColors } from '../../../theme/colors'
import { Button, Input, Text } from '../../atoms'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useQuery } from '@tanstack/react-query'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { toast } from 'react-toastify'
import useAuth from '../../../hooks/useAuth'
import { screen } from '../../../theme'

const Wrapper = styled(motion.div)``
const WhiteArea = styled.div`
    padding: 24px;
    border-radius: 32px;
    background: ${africredColors.neutrals.white[10]};
    height: fit-content;

    > div:not(:last-child) {
        border-bottom: 1px solid ${africredColors.neutrals.night[200]};
        padding-bottom: 16px;
    }
`

const IconText = styled(Text)`
    font-weight:500;
`
const TransactionTypeSelector = styled.div`
    padding: 8px;
    border-radius: 16px;
    display: flex;
    background: ${africredColors.neutrals.night[50]};
    border-bottom: none !important;
    padding-bottom: 8px !important;
    width: fit-content;
    align-items:center;
    overflow:scroll;
    @media only screen and (${screen.sm}) {
        overflow:scroll;
    }
    
`

const Options = styled.div`
    display: flex;
    > label {
        display: block;
        padding: 12px 16px;
        border-radius: 8px;
        min-width: 133px;
        text-align: center;
        cursor: pointer;
    }

    > input[type='radio'] {
        display: none;
    }

    input[type='radio']:checked + label {
        background: ${africredColors.neutrals.white[10]};
    }
`
const MenuLabel = styled.label`
    font-size:12px;
    @media only screen and (${screen.md}) {
        font-size:16px;
    }
`

const StyledInputWithIcon = styled(Input)`

`

const RateInput = styled.div`
    background: ${africredColors.neutrals.white["100"]};
`

export const PricingSetup = () => {
    const [selectedTransactionOption, setSelectedTransactionOption] =
        useState('loanProcessingFee')
    const axiosPrivate = useAxiosPrivate()
    const [serviceFees, setServiceFees] = useState(null)
    const [refresh, setRefresh] = useState(false)
    const auth = useAuth()

    const [selectedTransactionFlag, setSelectedTransactionFlag] = useState(auth.enumValues.transactionFlags[0].value)

    const {
        isPending,
        error,
        data: appConfig,
    } = useQuery({
        queryKey: ['loans'],
        queryFn: async () => {
            const response = await axiosPrivate('/app-configuration/get-config')
            if (response.data) {
                setServiceFees(response.data)
            }
            return response.data
        },
    })

    function getSectionTitle(selectedOption) {
        if (selectedOption === 'loanProcessingFee') return 'Loan'
        if (selectedOption === 'pofProcessingFee') return 'Short term loans'
        if (selectedOption === 'remittanceProcessingFee') return 'Remittance'
        if (selectedOption === 'universityAdmissionProcessingFee')
            return 'AfriCred Admit'
    }

    async function updateConfig(data) {
        const isContinue = window.confirm(
            'This is a sensitive action and will affect how money is calculated accross board. Do you want to continue?',
        )
        if (isContinue) {
            const response = await axiosPrivate.patch(
                '/app-configuration/update',
                data,
            )
            if (response.data) {
                toast.success('Config update was success full')
            } else {
                toast.error(
                    'There was an error updating your config. Please contact support!',
                )
            }
        }
    }

    if (isPending) return 'Loading...'

    if (error) return 'An error has occurred: ' + error.message

    if (!appConfig) return 'No config found'

    

    return (
        <>
            {serviceFees && (
                <Wrapper>
                    <div className="flex justify-between">
                        <Text type="h3" weight="medium">
                            Price Setup
                        </Text>

                        <Button
                            size="sm"
                            border={`1px solid ${africredColors.neutrals.night[950]}`}
                            onClick={() => updateConfig(serviceFees)}
                        >
                            <Text type="p" weight="medium" textWrap="nowrap">
                                Update changes
                            </Text>
                        </Button>
                    </div>

                    <div className="flex flex-col gap-4 mt-6">
                        <WhiteArea className="flex flex-col gap-4">
                            <TransactionTypeSelector>
                                <Options>
                                    <input
                                        type="radio"
                                        id="loanProcessingFee"
                                        name="transactionType"
                                        value="loanProcessingFee"
                                        checked={
                                            selectedTransactionOption ===
                                            'loanProcessingFee'
                                        }
                                        onChange={() =>
                                            setSelectedTransactionOption(
                                                'loanProcessingFee',
                                            )
                                        }
                                    />
                                    <label className={`sm:text-sm md:text-md lg:text-base`} htmlFor="loanProcessingFee">
                                        Loan
                                    </label>
                                </Options>

                                <Options>
                                    <input
                                        type="radio"
                                        name="transactionType"
                                        id="pofProcessingFee"
                                        value="pofProcessingFee"
                                        checked={
                                            selectedTransactionOption ===
                                            'pofProcessingFee'
                                        }
                                        onChange={() =>
                                            setSelectedTransactionOption(
                                                'pofProcessingFee',
                                            )
                                        }
                                    />
                                    <label className={`sm:text-sm md:text-md lg:text-base`} htmlFor="pofProcessingFee">
                                        Short term loans
                                    </label>
                                </Options>
                                {/* <Options>
                                    <input
                                        type="radio"
                                        name="transactionType"
                                        id="remittanceProcessingFee"
                                        value="remittanceProcessingFee"
                                        checked={
                                            selectedTransactionOption ===
                                            'remittanceProcessingFee'
                                        }
                                        onChange={() =>
                                            setSelectedTransactionOption(
                                                'remittanceProcessingFee',
                                            )
                                        }
                                    />
                                    <label className={`sm:text-sm md:text-md lg:text-base`} htmlFor="remittanceProcessingFee">
                                        Remittance
                                    </label>
                                </Options> */}
                                <Options>
                                    <input
                                        type="radio"
                                        name="transactionType"
                                        id="universityAdmissionProcessingFee"
                                        value="universityAdmissionProcessingFee"
                                        checked={
                                            selectedTransactionOption ===
                                            'universityAdmissionProcessingFee'
                                        }
                                        onChange={() =>
                                            setSelectedTransactionOption(
                                                'universityAdmissionProcessingFee',
                                            )
                                        }
                                    />
                                    <label className={`sm:text-sm md:text-md lg:text-base`} htmlFor="universityAdmissionProcessingFee">
                                        AfriCred Admit
                                    </label>
                                </Options>
                            </TransactionTypeSelector>
                            <Text
                                type="h4"
                                weight="medium"
                                color="rgba(0, 0, 0, 0.80);"
                            >
                                {getSectionTitle(selectedTransactionOption)}
                            </Text>
                            <div className="flex justify-between items-center mb-4">
                                <div className="flex flex-col gap-2">
                                    <Text
                                        type="h5"
                                        weight="medium"
                                        color="rgba(0, 0, 0, 0.80);"
                                    >
                                        Service fee
                                    </Text>
                                    <Text color="#5D5D5D" type="p">
                                        This applies to all{' '}
                                        {getSectionTitle(
                                            selectedTransactionOption,
                                        )?.toLowerCase()}{' '}
                                        applications
                                    </Text>
                                </div>

                                <div className="w-[195px]">
                                    <Input
                                        type="text"
                                        value={
                                            serviceFees[
                                                selectedTransactionOption
                                            ]
                                        }
                                        onChange={e =>
                                            setServiceFees(prev => ({
                                                ...prev,
                                                [selectedTransactionOption]:
                                                    e.target.value,
                                            }))
                                        }
                                        size="md"
                                        padding="14px"
                                        icon={
                                            true ? (
                                                <Text
                                                    type="p"
                                                    weight="medium"
                                                    className="mr-[-10px]"
                                                >
                                                    USD
                                                </Text>
                                            ) : selectedTransactionOption ===
                                              'universityAdmissionProcessingFee' ? (
                                                <Text
                                                    type="p"
                                                    weight="medium"
                                                    className="mr-[-10px]"
                                                >
                                                    USD
                                                </Text>
                                            ) : (
                                                '%'
                                            )
                                        }
                                        iconposition="right"
                                    />
                                </div>
                            </div>
                        </WhiteArea>

                        <WhiteArea>
                            <TransactionTypeSelector style={{ marginBottom: 20 }}>
                                {auth.enumValues.transactionFlags.map((item, index) => (
                                <Options key={index}>
                                    <input
                                        type="radio"
                                        name="transactionFlag"
                                        id={item.value}
                                        value={item.value}
                                        checked={
                                            selectedTransactionFlag ===
                                            item.value
                                        }
                                        onClick={() => {
                                            setSelectedTransactionFlag(
                                                item.value
                                            )
                                        }}
                                    />
                                    <label 
                                        className={`sm:text-sm md:text-md lg:text-base`} 
                                        htmlFor={item.value}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            paddingTop: "16px",
                                            paddingBotton: "16px",
                                        }}
                                    >
                                    <img src={item.icon} alt="flag" style={{ marginRight: 10 }}/>
                                        {item.value} Rate
                                    </label>
                                </Options>
                                ))}
                            </TransactionTypeSelector>

                            <div className="flex flex-col gap-4 mb-4">
                                <div className="grid grid-cols-1 sm:grid-cols-2 min-[920px]:grid-cols-3 lg:grid-cols-4 min-[1900]:grid-cols-5 gap-x-4 gap-y-6">
                                    {auth.enumValues.transactionFlags.map(
                                        item => (
                                            <RateInput className='rounded-full px-6 py-2 flex justify-center items-center w-full'>
                                                <div className='w-1/2'>
                                                    <Text style={{color:`${africredColors.neutrals.night["400"]}`,
                                                        fontWeight:"700"}}> {`${selectedTransactionFlag} to ${item.value}`}</Text>
                                                </div>
                                                <Input
                                                    type="number"
                                                    value={    
                                                        serviceFees?.rates[selectedTransactionFlag][`To${item.value}`]  
                                                    }
                                                    style={{
                                                        background:"transparent", 
                                                        textAlign:"right", 
                                                        justifySelf:"flex-end", 
                                                        paddingLine:"2px",
                                                        paddingBlock:0,
                                                        color:`${africredColors.neutrals.night["700"]}`,
                                                        fontWeight:"450"
                                                    }}
                                                    onChange={e =>{
                                                        setServiceFees(prev => {
                                                            prev.rates[selectedTransactionFlag][`To${item.value}`] = Number(e.target.value);
                                                            return prev;
                                                        })
                                                        setRefresh(!refresh)
                                                    }}
                                                    size="sm"
                                                    // iconposition="left"
                                                    // icon={`USD to ${item.value}`}
                                                />
                                            </RateInput> 
                                        )
                                    )}
                                </div>
                            </div>
                        </WhiteArea>

                        {/* <WhiteArea className="flex flex-col gap-4">
                            <Text
                                type="h4"
                                weight="medium"
                                color="rgba(0, 0, 0, 0.80);"
                            >
                                USD Rate
                            </Text>
                            <div className="flex flex-col gap-4 mb-4">
                                <div className="flex flex-col gap-2">
                                    <Text
                                        type="h5"
                                        weight="medium"
                                        color="rgba(0, 0, 0, 0.80);"
                                    >
                                        Dollar to Naira Amount
                                    </Text>
                                    <Text color="#5D5D5D" type="p">
                                        This applies to all transaction done in
                                        the platform
                                    </Text>
                                </div>

                                <div className="flex flex-wrap gap-4">
                                    {auth.enumValues.transactionFlags.map(
                                        item => (
                                            <div className="w-[195px]">
                                                <Input
                                                    type="text"
                                                    value={
                                                        serviceFees.rates[
                                                            item.value
                                                        ]
                                                    }
                                                    onChange={e =>
                                                        setServiceFees(
                                                            prev => ({
                                                                ...prev,
                                                                rates: {
                                                                    ...prev.rates,
                                                                    [item.value]:
                                                                        e.target
                                                                            .value,
                                                                },
                                                            }),
                                                        )
                                                    }
                                                    size="md"
                                                    padding="12px 14px 14px 90px"
                                                    icon={
                                                        <div className="flex gap-2 items-center ml-[34px]">
                                                            <img
                                                                src={item.icon}
                                                                alt={item.value}
                                                                style={{
                                                                    width: '24px',
                                                                    height: '24px',
                                                                    borderRadius:
                                                                        '24px',
                                                                }}
                                                            />
                                                            <Text
                                                                type="p"
                                                                weight="medium"
                                                            >
                                                                {item.value}
                                                            </Text>
                                                        </div>
                                                    }
                                                    iconposition="left"
                                                />
                                            </div>
                                        ),
                                    )}
                                </div>
                            </div>
                        </WhiteArea> */}

                        {/* <WhiteArea className="flex flex-col gap-4">
                            <Text
                                type="h4"
                                weight="medium"
                                color="rgba(0, 0, 0, 0.80);"
                            >
                                Agent Level fees
                            </Text>

                            <div className="flex justify-between items-center mb-2">
                                <div className="flex flex-col gap-2">
                                    <Text
                                        type="h5"
                                        weight="medium"
                                        color="rgba(0, 0, 0, 0.80);"
                                    >
                                        Silver
                                    </Text>
                                    <Text color="#5D5D5D" type="p">
                                        This applies to all Loan
                                        applications
                                    </Text>
                                </div>

                                <div className="w-[195px]">
                                    <Input
                                        type="text"
                                        value={
                                            serviceFees?.agentLevel
                                                ?.silverPercentage
                                        }
                                        onChange={e =>
                                            setServiceFees(prev => ({
                                                ...prev,
                                                agentLevel: {
                                                    ...prev.agentLevel,
                                                    silverPercentage:
                                                        e.target.value,
                                                },
                                            }))
                                        }
                                        size="md"
                                        padding="14px"
                                        icon="%"
                                        iconposition="right"
                                    />
                                </div>
                            </div>

                            <div className="flex justify-between items-center mb-2">
                                <div className="flex flex-col gap-2">
                                    <Text
                                        type="h5"
                                        weight="medium"
                                        color="rgba(0, 0, 0, 0.80);"
                                    >
                                        Gold
                                    </Text>
                                    <Text color="#5D5D5D" type="p">
                                        This applies to all loan Application
                                    </Text>
                                </div>

                                <div className='flex flex-col gap-2 md:flex-row items-center'>
                                    <div className="">
                                        <div className="w-[195px]">
                                            <Input
                                                type="text"
                                                value={
                                                    serviceFees?.agentLevel
                                                        ?.goldLevel?.percentageRate
                                                }
                                                onChange={e =>
                                                    setServiceFees(prev => ({
                                                        ...prev,
                                                        agentLevel: {
                                                            ...prev.agentLevel,
                                                            goldLevel: {
                                                                ...prev.agentLevel
                                                                    .goldLevel,
                                                                    noOfApplications:
                                                                    e.target.value,
                                                            },
                                                        },
                                                    }))
                                                }
                                                size="md"
                                                padding="14px"
                                                icon={<IconText>{serviceFees?.agentLevel?.goldLevel?.cycle}</IconText>}
                                                iconposition="right"
                                            />
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="w-[195px]">
                                            <Input
                                                type="text"
                                                value={
                                                    serviceFees?.agentLevel
                                                        ?.goldLevel?.percentageRate
                                                }
                                                onChange={e =>
                                                    setServiceFees(prev => ({
                                                        ...prev,
                                                        agentLevel: {
                                                            ...prev.agentLevel,
                                                            goldLevel: {
                                                                ...prev.agentLevel
                                                                    .goldLevel,
                                                                percentageRate:
                                                                    e.target.value,
                                                            },
                                                        },
                                                    }))
                                                }
                                                size="md"
                                                padding="14px"
                                                icon="%"
                                                iconposition="right"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-between items-center mb-2">
                                <div className="flex flex-col gap-2">
                                    <Text
                                        type="h5"
                                        weight="medium"
                                        color="rgba(0, 0, 0, 0.80);"
                                    >
                                        Diamond
                                    </Text>
                                    <Text color="#5D5D5D" type="p">
                                        This applies to all loan applications
                                    </Text>
                                </div>

                                <div className='flex flex-col md:flex-row gap-2 items-center'>
                                    <div className="">
                                        <div className="w-[195px]">
                                            <Input
                                                type="text"
                                                value={
                                                    serviceFees?.agentLevel
                                                        ?.platinumLevel?.percentageRate
                                                }
                                                onChange={e =>
                                                    setServiceFees(prev => ({
                                                        ...prev,
                                                        agentLevel: {
                                                            ...prev.agentLevel,
                                                            platinumLevel: {
                                                                ...prev.agentLevel
                                                                    .platinumLevel,
                                                                    noOfApplications:
                                                                    e.target.value,
                                                            },
                                                        },
                                                    }))
                                                }
                                                size="md"
                                                padding="14px"
                                                icon={<IconText>{serviceFees?.agentLevel?.platinumLevel?.cycle}</IconText>}
                                                iconposition="right"
                                            />
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="w-[195px]">
                                            <Input
                                                type="text"
                                                value={
                                                    serviceFees?.agentLevel
                                                        ?.platinumLevel?.percentageRate
                                                }
                                                onChange={e =>
                                                    setServiceFees(prev => ({
                                                        ...prev,
                                                        agentLevel: {
                                                            ...prev.agentLevel,
                                                            platinumLevel: {
                                                                ...prev.agentLevel
                                                                    .platinumLevel,
                                                                percentageRate:
                                                                    e.target.value,
                                                            },
                                                        },
                                                    }))
                                                }
                                                size="md"
                                                padding="14px"
                                                icon="%"
                                                iconposition="right"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </WhiteArea> */}

                        <WhiteArea className="flex flex-col gap-4">
                            <Text
                                type="h4"
                                weight="medium"
                                color="rgba(0, 0, 0, 0.80);"
                            >
                                Agent Commissions
                            </Text>

                            <div className="flex justify-between items-center mb-2">
                                <div className="flex flex-col gap-2">
                                    <Text
                                        type="h5"
                                        weight="medium"
                                        color="rgba(0, 0, 0, 0.80);"
                                    >
                                        Remittance
                                    </Text>
                                    <Text color="#5D5D5D" type="p">
                                        This applies to all remittance
                                        applications
                                    </Text>
                                </div>

                                <div className="w-[195px]">
                                    <Input
                                        type="text"
                                        value={
                                            serviceFees?.agentCommission
                                                ?.remittance
                                        }
                                        onChange={e =>
                                            setServiceFees(prev => ({
                                                ...prev,
                                                agentCommission: {
                                                    ...prev.agentCommission,
                                                    remittance:
                                                        e.target.value,
                                                },
                                            }))
                                        }
                                        size="md"
                                        padding="14px"
                                        icon="USD"
                                        iconposition="right"
                                    />
                                </div>
                            </div>

                            <div className="flex justify-between items-center mb-2">
                                <div className="flex flex-col gap-2">
                                    <Text
                                        type="h5"
                                        weight="medium"
                                        color="rgba(0, 0, 0, 0.80);"
                                    >
                                        University Admissions
                                    </Text>
                                    <Text color="#5D5D5D" type="p">
                                        This applies to all university
                                        applications
                                    </Text>
                                </div>

                                <div className="flex gap-2 items-center">
                                    <div className="w-[195px]">
                                        <Input
                                            type="text"
                                            value={
                                                serviceFees?.agentCommission
                                                    ?.admissionPercentage
                                            }
                                            onChange={e =>
                                                setServiceFees(prev => ({
                                                    ...prev,
                                                    agentCommission: {
                                                        ...prev.agentCommission,
                                                        admissionPercentage: e.target.value
                                                    },
                                                }))
                                            }
                                            size="md"
                                            padding="14px"
                                            icon="%"
                                            iconposition="right"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-between items-center mb-2">
                                <div className="flex flex-col gap-2">
                                    <Text
                                        type="h5"
                                        weight="medium"
                                        color="rgba(0, 0, 0, 0.80);"
                                    >
                                        USD/CAD Loans
                                    </Text>
                                    <Text color="#5D5D5D" type="p">
                                        This applies to all loan applications
                                    </Text>
                                </div>

                                <div className="flex gap-2 items-center">
                                    <div className="w-[195px]">
                                        <Input
                                            type="text"
                                            value={
                                                serviceFees?.agentCommission
                                                    ?.loanAndPofPercentage
                                            }
                                            onChange={e =>
                                                setServiceFees(prev => ({
                                                    ...prev,
                                                    agentCommission: {
                                                        ...prev.agentCommission,
                                                        loanAndPofPercentage: e.target.value,
                                                    },
                                                }))
                                            }
                                            size="md"
                                            padding="14px"
                                            icon="%"
                                            iconposition="right"
                                        />
                                    </div>
                                </div>
                            </div>
                        </WhiteArea>
                    </div>
                </Wrapper>
            )}
        </>
    )
}

function getRateKey(currency){
    if(currency.toLowerCase() === "usd"){
        return `USD`
    }
    return `usdTo${currency}`
}

import React from "react";
import styled from "styled-components";
import { Icon, Text } from "../../atoms";
import { africredColors } from "../../../theme/colors";
import { Accordion } from "../../molecules";
import { camelCaseToSeparateWords } from "../../../utils";

const TimeWrapper = styled.div`
  padding: 0 0 32px 24px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;

  &:not(:last-child) {
    border-left: 2px solid
      ${({ completed }) =>
        completed
          ? africredColors.primary.green[600]
          : africredColors.neutrals.night[300]};
  }

  > .time-icon {
    position: absolute;
    top: 0;
    left: -13px;
    border-radius: 50%;
    padding: 5px;
  }

  > .title {
    margin-top: -2px;
  }
`;
const IconWrapper = styled.div`
  background-color: ${({ bgcolor }) => bgcolor || "white"};
`;
export const AttachDocumentButton = styled.button`
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 13px;
  border-radius: 8px;
  background: #eee;
`;
export const AttachedDocumentPreview = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  border-radius: 8px;
  border: 1px solid #d1d1d1;
`;
export const AttachmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const LoadingBarWrapper = styled.div`
  width: 90%;
  height: 3px;
  background: ${africredColors.neutrals.night[100]};
`;
export const LoadingBar = styled.div`
  width: 50%;
  height: inherit;
  background: ${africredColors.primary.green[600]};
`;

export const StepProgressIndicator = ({ data, requestDocument }) => {
  function convertStagesDataToArray(data) {
    const keysArr = Object.keys(data);

    const newArr = keysArr.map((item) => ({
      title: item,
      ...data[item],
    }));

    return newArr;
  }

  const progress = convertStagesDataToArray(data.approvalStages)?.map(
    (item) => ({
      title: camelCaseToSeparateWords(
        item?.title.substring(0, item?.title.length - 5)
      ),
      icon: (
        <Icon
          type="checked-circle"
          stroke={
            item.completed
              ? africredColors.primary.green[600]
              : africredColors.neutrals.night[300]
          }
        />
      ),
      content: (
        <Accordion
          showIcon
          header={
            <Text
              type="p"
              color={africredColors.neutrals.night[300]}
              hovercolor={africredColors.neutrals.night[700]}
            >
              "AfriCred is currently reviewing you application. Further document
              upload is optional"
            </Text>
          }
          body={
            <AttachmentWrapper className="mt-6">
              <AttachDocumentButton
                onClick={() => {
                  requestDocument({
                    open: true,
                    stage: item.title,
                  });
                }}
              >
                <Icon type="doc-required-stat" /> Request document
              </AttachDocumentButton>

              {item?.documents?.map((item) => {
                const filenameArr = item.Path.split("/");
                const filename = filenameArr[filenameArr.length - 1];
                return (
                  <AttachedDocumentPreview>
                    <div className="w-full flex justify-between">
                      <div className="flex flex-col gap-1">
                        <Text type="p" weight="medium">
                          {filename}
                        </Text>
                        <Text>{item.Size}KB</Text>
                      </div>

                      <div className="flex gap-2">
                        {/* <Icon type="half-loading" /> */}
                        <a
                          href={item.Location}
                          download={filename}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Icon type="download-doc" />
                        </a>

                        <Icon type="bin" stroke="#FF677B" />
                      </div>
                    </div>
                  </AttachedDocumentPreview>
                );
              })}
            </AttachmentWrapper>
          }
        />
      ),
      completed: item.completed,
    })
  );

  return (
    <>
      {progress.map((item, idx) => (
        <TimeWrapper completed={item.completed} key={idx}>
          <Text type="h5" className="title">
            {item.title}
          </Text>
          {item.content}
          <IconWrapper
            bgcolor={
              item?.completed
                ? africredColors.primary.green[50]
                : africredColors.neutrals.night[80]
            }
            className="time-icon"
          >
            {item.icon}
          </IconWrapper>
        </TimeWrapper>
      ))}
    </>
  );
};

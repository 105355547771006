import React, { useContext, useEffect, useRef, useState } from 'react'
import { Text } from '../../atoms/Text'
import styled from 'styled-components'
import { africredColors } from '../../../theme/colors'
import { Button, Icon, Input, PasswordInput, SelectInput } from '../../atoms'
import { Table } from '../../molecules'
import profileImage from '../../../assets/pngs/dp.png'
import profileImg from '../../../assets/pngs/dp.png'
import { pageAnimationConfig, sortWithDate, filterAdmin } from '../../../utils'
import { motion } from 'framer-motion'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { screen } from '../../../theme'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { TableFilter } from '../../organisms'
import { RequestInfoModal } from '../../molecules/Modal/RequestInfoModal'
import { GridActionsCellItem } from '@mui/x-data-grid'
import AuthContext from '../../../store/contexts/AuthProvider'
import useAuth from '../../../hooks/useAuth'
import { toast } from 'react-toastify'
import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import { useGridApiRef } from '@mui/x-data-grid'
import { SuccessModal } from '../../molecules/Modal/SuccessModal'

const Wrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
const ContentWrapper = styled.div`
    width: 100%;
    max-height: calc(84vh - 94px);
    border-radius: 32px;
    background: ${africredColors.neutrals.white[10]};
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media only screen and (${screen.md}) {
        padding: 24px;
    }
`
const TableWrapper = styled.div`
    width: 100%;
    overflow: auto;
    max-height: calc(75vh - 94px);
`
const Flex = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`
const ProfilePictureRow = styled.div`
    display: flex;
    gap: 24px;
    margin: 38px 0 24px 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    > img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
    }
`
const ProfilePicUploadInput = styled.input`
    width: 0;
    visibility: hidden;
`
function renderHeader(params) {
    return (
        <Text type="p" weight="medium">
            {params.colDef.headerName}
        </Text>
    )
}
const FormRow = styled.div`
    display: flex;
    gap: 16px;
`

export const ManageAdmins = () => {
    const auth = useAuth()

    if (auth.role !== 'Super Admin') {
        return (
            <div className="flex justify-center items-center h-[50vh]">
                Ooops... You do not have clearance to access this page!
            </div>
        )
    }

    return <ManageAdminPage />
}

const ManageAdminPage = () => {
    const axiosPrivate = useAxiosPrivate()
    const { isMobile } = useScreenSize()
    const [openModal, setOpenModal] = useState(false)
    const [adminDetails, setAdminDetails] = useState(null)
    const [addAdminSuccess, setAddAdminSuccess] = useState(false)
    const [filterData, setFilterData] = useState({
        searchTerm: '',
        sort: '',
    })
    const apiRef = useGridApiRef()

    const {
        isPending,
        error,
        data: admins,
        refetch,
    } = useQuery({
        queryKey: ['all-admins'],
        queryFn: async () => {
            const response = await axiosPrivate('/admin/all-admin')
            return response.data.map(item => ({
                id: item.id,
                fullName: `${item.firstName} ${item.lastName}`,
                email: item.email,
                role: item.role,
                status: item.accountStatus,
                lastLogin: item.updatedAt,
                createdAt: item.createdAt,
                date: item.createdAt,
            }))
        },
    })

    useEffect(() => {
        async function getLoans() {
            try {
            } catch (err) {}
        }

        getLoans()
    }, [axiosPrivate])

    console.log('admins', admins)

    async function blockUser(id) {
        try {
            const response = await axiosPrivate.patch(`/admin/block/${id}`)
            if (response.data) {
                toast.success(
                    `Admin is now ${response.data.accountStatus?.toLowerCase()}`,
                )
                refetch()
            }
        } catch (err) {
            toast.error(err.response.data.message)
        }
    }

    async function deleteAdmin(email) {
        try {
            const response = await axiosPrivate.delete(`/admin/delete/${email}`)
            if (response.data) {
                toast.success('User deletion completed')
                refetch()
            }
        } catch (err) {
            toast.error(err.response.data.message)
        }
    }

    if (isPending)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                <div className="w-10 h-10">
                    <Icon type="spinner" />
                </div>
                Loading
            </div>
        )

    if (error)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                An error has occurred: {error.message}
            </div>
        )

    const columns = [
        {
            field: 'fullName',
            headerName: 'Full name',
            minWidth: 100,
            flex: 2,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Flex>
                        <img
                            style={{
                                width: '35px',
                                height: '35px',
                                borderRadius: '50%',
                            }}
                            src={row.avatar || profileImage}
                            alt={`profile${value}`}
                        />
                        <Text type="p">{value}</Text>
                    </Flex>
                )
            },
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 150,
            flex: 2,
            renderHeader,
        },
        {
            field: 'role',
            headerName: 'Role',
            minWidth: 150,
            flex: 1,
            renderHeader,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderHeader,
        },
        {
            field: 'lastLogin',
            headerName: 'Last login',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return value && format(new Date(value), 'dd MMM, yyyy')
            },
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            sortable: false,
            minWidth: 130,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return value && format(new Date(value), 'dd MMM, yyyy')
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 80,
            renderHeader,

            getActions: params => [
                ...[
                    <GridActionsCellItem
                        label={
                            params.row.status === 'Active' ? 'Block' : 'Unblock'
                        }
                        showInMenu
                        onClick={() => blockUser(params.row.id)}
                    />,
                    <GridActionsCellItem
                        label="Delete Admin"
                        showInMenu
                        onClick={() => deleteAdmin(params.row.email)}
                    />,
                    <GridActionsCellItem
                        label="Update Admin"
                        showInMenu
                        onClick={() => {
                            setAdminDetails(params.row)
                            setOpenModal(true)
                        }}
                    />,
                ],
            ],
        },
    ]

    return (
        <Wrapper {...pageAnimationConfig}>
            <div className="flex justify-between">
                <div>
                    <Text type="h3" weight="medium">
                        Manage Admins
                    </Text>
                    <Text type="p" textWrap="nowrap">
                        All admins
                    </Text>
                </div>

                <Button
                    size="sm"
                    border={`1px solid ${africredColors.neutrals.night[950]}`}
                    onClick={() => {
                        setOpenModal(true)
                    }}
                >
                    {!isMobile && <Icon type="wallet-sm" />}

                    <Text type="p" weight="medium" textWrap="nowrap">
                        Add New
                    </Text>
                </Button>
            </div>

            <ContentWrapper>
                <div className="flex justify-between items-center gap-y-2 items-center flex-wrap lg:flex-nowrap">
                    <TableFilter
                        showsCsvButton
                        filterData={filterData}
                        setFilterData={setFilterData}
                        apiRef={apiRef}
                        csvFileName="AfriCred Admins"
                    />
                </div>
                <TableWrapper>
                    <Table
                        apiRef={apiRef}
                        columns={isMobile ? columns.slice(0, 3) : columns}
                        rows={admins
                            .sort(sortWithDate(filterData))
                            .filter(filterAdmin(filterData))}
                        pageSize="8"
                    />
                </TableWrapper>
            </ContentWrapper>

            {!addAdminSuccess && (adminDetails || openModal) && (
                <AddAdminModal
                    openModal={openModal}
                    setOpenModal={state => {
                        setAdminDetails(null)
                        setOpenModal(state)
                    }}
                    setSuccessState={setAddAdminSuccess}
                    adminData={adminDetails}
                    setAdminDetails={setAdminDetails}
                    refetch={refetch}
                />
            )}

            {addAdminSuccess && (
                <SuccessModal
                    title="Created successfully"
                    subTitle="A hassle-free education loan processing and global education fees remittance with the best rates available from the global markets."
                    ctaText="View admins"
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    bgcolor={africredColors.neutrals.night[950]}
                    color="white"
                />
            )}
        </Wrapper>
    )
}

const AddAdminModal = ({
    openModal,
    setOpenModal,
    setSuccessState,
    adminData,
    setAdminDetails,
    refetch,
}) => {
    const defaultData = {
        firstName: adminData ? adminData.fullName.split(' ')[0] : '',
        lastName: adminData ? adminData.fullName.split(' ')[1] : '',
        email: adminData ? adminData.email : '',
        role: adminData ? adminData.role : '',
    }
    const [data, setData] = useState(defaultData)
    const { isMobile } = useScreenSize()
    const auth = useAuth()
    const { setAuth } = useContext(AuthContext)
    const axiosPrivate = useAxiosPrivate()

    // Image upload code starts
    const inputImageRef = useRef(null)
    const facePhoto = auth?.profilePicture?.Location
        ? auth?.profilePicture?.Location
        : profileImg
    const [photo, setPhoto] = useState(facePhoto)

    async function handlePhotoInput(e) {
        const image = URL.createObjectURL(e.target.files[0])

        setPhoto(image)
        const imageFormData = new FormData()
        imageFormData.append('file', e.target.files[0])

        const response = await axiosPrivate.post(
            '/admin/upload-profile-picture',
            imageFormData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                },
            },
        )

        if (response.data) {
            setAuth(prev => ({
                ...prev,
                profilePicture: response.data.profilePicture,
            }))
        }
    }

    useEffect(() => {
        setPhoto(facePhoto)
    }, [facePhoto, setPhoto])

    async function addAdmin() {
        if (data.password === data.confirmPassword) {
            try {
                const response = await axiosPrivate.post(
                    `/admin/add-admin`,
                    data,
                )
                if (response.data) {
                    setSuccessState(true)
                }
            } catch (error) {
                toast.error(error.response.data.message)
            }
        } else {
            toast.error(
                'Please confirm that both password and confirm Password text match',
            )
        }
    }

    async function updateAdmin() {
        const payload = {
            email: data.email,
            password: data.password,
            firstName: data.firstName,
            lastName: data.lastName,
            role: data.role,
            admin: adminData.id,
        }
        try {
            const response = await axiosPrivate.patch(
                `/admin/update-admin`,
                payload,
            )
            if (response.data) {
                setAdminDetails(null)
                toast.success('Admin update completed')
                refetch()
            }
        } catch (err) {
            toast.error(err.response.data.message)
        }
    }

    return (
        <RequestInfoModal
            open={openModal}
            setOpen={setOpenModal}
            title={adminData ? 'Update Admin' : 'New Admin'}
        >
            <ProfilePictureRow>
                <img src={photo} alt="profile-img" />
                <Button
                    size="sm"
                    border={`1px solid ${africredColors.neutrals.night[950]}`}
                    onClick={() => inputImageRef?.current?.click?.()}
                >
                    {!isMobile && <Icon type="image" />}
                    <Text type="p" weight="bold" textWrap="nowrap">
                        Upload photo
                    </Text>
                </Button>
                <ProfilePicUploadInput
                    ref={inputImageRef}
                    type="file"
                    accept="image/*"
                    onChange={e => {
                        const response = handlePhotoInput(e)
                        toast.promise(response, {
                            pending: 'Uploading image',
                            success: 'Upload successful 👌',
                            error: 'Upload failed 🤯',
                        })
                    }}
                />
            </ProfilePictureRow>

            <div className="flex flex-col gap-4">
                <FormRow>
                    <div className="flex flex-col gap-4">
                        <Text type="p" weight="bold">
                            First Name
                        </Text>
                        <Input
                            icon={<Icon type="person" />}
                            type="text"
                            size="md"
                            placeholder="First Name"
                            autoComplete="off"
                            value={data?.firstName}
                            onChange={e => {
                                setData(prev => ({
                                    ...prev,
                                    firstName: e.target.value,
                                }))
                            }}
                        />
                    </div>
                    <div className="flex flex-col gap-4">
                        <Text type="p" weight="bold">
                            Last name
                        </Text>
                        <Input
                            icon={<Icon type="person" />}
                            type="text"
                            size="md"
                            placeholder="Last Name"
                            autoComplete="off"
                            value={data.lastName}
                            onChange={e => {
                                setData(prev => ({
                                    ...prev,
                                    lastName: e.target.value,
                                }))
                            }}
                        />
                    </div>
                </FormRow>

                <FormRow>
                    <div className="flex flex-col gap-4 w-full">
                        <Text type="p" weight="bold">
                            Email
                        </Text>
                        <Input
                            icon={<Icon type="email" />}
                            type="email"
                            size="md"
                            placeholder="Enter email"
                            autoComplete="off"
                            value={data?.email}
                            onChange={e => {
                                setData(prev => ({
                                    ...prev,
                                    email: e.target.value,
                                }))
                            }}
                        />
                    </div>
                    <div className="flex w-full flex-col gap-4">
                        <Text type="p" weight="bold">
                            Role
                        </Text>
                        <SelectInput
                            options={['Super Admin', 'Admin']}
                            value={data.role}
                            setValue={val =>
                                setData(prev => ({ ...prev, role: val }))
                            }
                            size="md"
                            width="100%"
                        />
                    </div>
                </FormRow>

                <div className="flex flex-col gap-4">
                    <Text type="p" weight="bold">
                        Password
                    </Text>
                    <PasswordInput
                        size="md"
                        placeholder="Enter password"
                        autoComplete="off"
                        value={data.password}
                        onChange={e =>
                            setData(prev => ({
                                ...prev,
                                password: e.target.value,
                            }))
                        }
                    />
                </div>

                <div className="flex flex-col gap-4">
                    <Text type="p" weight="bold">
                        Confirm password
                    </Text>
                    <PasswordInput
                        size="md"
                        padding="14px"
                        placeholder="Confirm password"
                        autoComplete="off"
                        value={data.confirmPassword}
                        onChange={e =>
                            setData(prev => ({
                                ...prev,
                                confirmPassword: e.target.value,
                            }))
                        }
                    />
                </div>

                <Button
                    className="mt-8"
                    onClick={adminData ? () => updateAdmin(data) : addAdmin}
                    width="100%"
                    size="md"
                    bgcolor={africredColors.neutrals.night[950]}
                >
                    <Text type="p" color="#fff" weight="bold">
                        {adminData ? 'Update admin' : 'Create password'}
                    </Text>
                </Button>
            </div>
        </RequestInfoModal>
    )
}

import React, { useEffect, useMemo, useState } from 'react'
import { Text } from '../../atoms/Text'
import { Icon } from '../../atoms'
import styled from 'styled-components'
import { africredColors } from '../../../theme/colors'
import { Table } from '../../molecules'
import profileImage from '../../../assets/pngs/dp.png'
import {
    pageAnimationConfig,
    sortWithDate,
    filterUser,
    formatDate,
} from '../../../utils'
import { motion } from 'framer-motion'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { screen } from '../../../theme'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { TableFilter } from '../../organisms'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import { UserModal } from '../../molecules/Modal/UserModal'
import { toast } from 'react-toastify'
import { useGridApiRef } from '@mui/x-data-grid'
import axiosPublic from '../../../api/axios'
import useDebounce from '../../utils/useDebounce'

const Wrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
const ContentWrapper = styled.div`
    width: 100%;
    max-height: calc(84vh - 94px);
    border-radius: 32px;
    background: ${africredColors.neutrals.white[10]};
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media only screen and (${screen.md}) {
        padding: 24px;
    }
`
const TableWrapper = styled.div`
    width: 100%;
    overflow: auto;
    max-height: calc(75vh - 94px);
`
const Flex = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`
function renderHeader(params) {
    return (
        <Text type="p" weight="medium">
            {params.colDef.headerName}
        </Text>
    )
}

export const Users = () => {
    const axiosPrivate = useAxiosPrivate()
    const [userDetails, setUserDetails] = useState(null)
    const [openDetailsModal, setOpenDetailsModal] = useState(false)
    const [filterData, setFilterData] = useState({
        searchTerm: '',
        sort: '',
        accountStatus: 'Active',
        accountType: '',
        dateRange: [],
        page: 1,
        pageSize: 100,
        export: false,
    })
    const apiRef = useGridApiRef()
    const searchTerm = useDebounce(filterData.searchTerm, 500)

    const { isPending: genericsDataPending, data: genericsData } = useQuery({
        queryKey: [],
        queryFn: async () => {
            const response = await axiosPublic(`/generic-apis/all-enum`)

            if (response.data) {
                return response.data
            }
        },
    })

    const { isPending, error, data, refetch } = useQuery({
        queryKey: [
            'get-users',
            filterData.dateRange &&
                filterData.dateRange[0]?.format('YYYY-MM-DD'),
            filterData.dateRange &&
                filterData.dateRange[1]?.format('YYYY-MM-DD'),
            filterData.accountStatus,
            filterData.accountType,
            filterData.page,
            filterData.pageSize,
            searchTerm,
        ],
        queryFn: async () => {
            const response = await axiosPrivate(
                `/account/get-users?accountStatus[0]=${
                    filterData.accountStatus
                }${
                    filterData.dateRange?.length
                        ? `&startDate=${filterData.dateRange[0].format(
                              'YYYY-MM-DD',
                          )}&endDate=${filterData.dateRange[1].format(
                              'YYYY-MM-DD',
                          )}`
                        : ''
                }
                ${
                    filterData.accountType
                        ? `&accountType[0]=${filterData.accountType}`
                        : ''
                }${searchTerm ? '&searchTerm=' + searchTerm : ''}&page=${
                    filterData.page
                }&limit=${filterData.pageSize}`,
            )

            return response.data
        },
    })

    const { isMobile } = useScreenSize()

    const users = useMemo(() => {
        return (
            data?.foundUsers?.map(item => ({
                id: item.id,
                avatar: item?.profilePicture?.Location,
                fullName: `${item.firstName || ''} ${item.lastName || ''}`,
                email: item.email || '',
                phone: `${item.phoneNumber?.code || ''} ${
                    item.phoneNumber?.number || ''
                }`,
                accountType: item.accountType,
                gender: item.gender,
                location: `${item.address?.state || ''}, ${
                    item.address?.country || ''
                }`,
                status: item.accountStatus,
                createdAt: item.createdAt,
                date: item.createdAt,
            })) || []
        )
    }, [data])

    const rowCount = useMemo(() => {
        return data?.count
    }, [data])

    const onPaginationModelChange = model => {
        setFilterData(prev => ({
            ...prev,
            page: model.page + 1,
            pageSize: model.pageSize,
        }))
    }

    const handleExport = () => {
        setFilterData(prev => ({
            ...prev,
            page: 1,
            pageSize: rowCount,
            export: true,
        }))
    }

    useEffect(() => {
        if (
            filterData.export &&
            !isPending &&
            filterData.pageSize === rowCount
        ) {
            handleExportClick()

            setFilterData(prev => ({
                ...prev,
                page: 1,
                pageSize: 100,
                export: false,
            }))
        }
    }, [filterData.export, isPending, rowCount, filterData.pageSize])

    const handleExportClick = () => {
        if (!apiRef.current) {
            return
        }

        const exportOptions = {
            fileName: `AfriCred Users`,
            allColumns: true,
        }

        apiRef.current.exportDataAsCsv(exportOptions)
    }

    if (isPending)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                <div className="w-10 h-10">
                    <Icon type="spinner" />
                </div>
                Loading
            </div>
        )

    if (error)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                An error has occurred: {error.message}
            </div>
        )

    async function blockUser(id) {
        try {
            const response = await axiosPrivate.patch(
                `/account/suspend-user/${id}`,
            )
            if (response.data) {
                toast.success(
                    `User is now ${response.data.accountStatus?.toLowerCase()}`,
                )
                refetch()
            }
        } catch (err) {
            toast.error(err.response.data.message)
        }
    }

    const columns = [
        {
            field: 'fullName',
            headerName: 'Full name',
            minWidth: 250,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Flex>
                        <img
                            style={{
                                width: '35px',
                                height: '35px',
                                borderRadius: '50%',
                            }}
                            src={row.avatar || profileImage}
                            alt={`profile${value}`}
                        />
                        <Text type="p">{value}</Text>
                    </Flex>
                )
            },
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 150,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            minWidth: 150,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'accountType',
            headerName: 'Account Type',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'gender',
            headerName: 'Gender',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'location',
            headerName: 'Location',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Text type="p" color={africredColors.primary.green[700]}>
                        {value}
                    </Text>
                )
            },
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            sortable: false,
            minWidth: 130,
            flex: 1.5,
            renderHeader,
            valueFormatter: ({ value }) => value && formatDate(value),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 80,
            renderHeader,

            getActions: params => [
                ...[
                    <GridActionsCellItem
                        label={
                            params.row.status === 'Active' ? 'Block' : 'Unblock'
                        }
                        showInMenu
                        onClick={() => blockUser(params.row.id)}
                    />,
                    <GridActionsCellItem
                        label="View Details"
                        showInMenu
                        onClick={() => {
                            setUserDetails(params.row)
                            if (userDetails) {
                                setOpenDetailsModal(true)
                            }
                        }}
                    />,
                ],
            ],
        },
    ]

    return (
        <Wrapper {...pageAnimationConfig}>
            <div>
                <Text type="h3" weight="medium">
                    Users
                </Text>
                <Text type="p" textWrap="nowrap">
                    All users
                </Text>
            </div>

            <ContentWrapper>
                <div className="flex justify-between items-center gap-y-2 items-center flex-wrap lg:flex-nowrap">
                    <TableFilter
                        showAccountType
                        showAccountStatus
                        showDateRange
                        showsCsvButton
                        filterData={filterData}
                        setFilterData={setFilterData}
                        apiRef={apiRef}
                        csvFileName="AfriCred Users"
                        accountStatusOptions={[
                            ...genericsData?.accountStatusEnum,
                        ]}
                        accountTypeOptions={[...genericsData?.accountTypeEnum]}
                        handleExport={handleExport}
                    />
                </div>
                <TableWrapper>
                    <Table
                        apiRef={apiRef}
                        columns={isMobile ? columns.slice(0, 3) : columns}
                        rows={users
                            // .sort(sortWithDate(filterData))
                            .filter(one => one)}
                        pageSize="100"
                        rowCount={rowCount}
                        page={filterData.page - 1}
                        onPaginationModelChange={onPaginationModelChange}
                    />
                </TableWrapper>
            </ContentWrapper>

            {userDetails && (
                <UserModal
                    title="Details"
                    open={openDetailsModal}
                    setOpen={setOpenDetailsModal}
                    data={userDetails}
                />
            )}
        </Wrapper>
    )
}
